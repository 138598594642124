import { useState } from "react";
import Spinner from "../../shared/Spinner";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { toast } from "react-toastify";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Visibility } from "@mui/icons-material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import { flattenObject } from "../../utils/sharedFunctions";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import {
  useDeleteDivisionMutation,
  useGetAllDivisionQuery,
} from "../../services/divisionAPI";

const DivisionComponent = () => {
  const navigate = useNavigate();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const { data, isLoading, isError, error, isSuccess } =
    useGetAllDivisionQuery();
  const [deleteDivision] = useDeleteDivisionMutation();
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              padding: "25px",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              // backgroundColor: "#FF0000",
            },
          },
        },
      },
    });

  const muiCache = createCache({
    key: "mui-datatables",
    prepend: true,
  });

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "standard.standard",
      label: "Standard",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "division",
      label: "Division",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "user.email",
      label: "Added By",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "addDate",
      label: "Date Added",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "editDate",
      label: "Date Updated",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title="View Division">
                <IconButton
                  onClick={() =>
                    navigate("/division/view/" + tableMeta.rowData[0])
                  }
                >
                  <Visibility color={"primary"} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit Division">
                <IconButton
                  onClick={() =>
                    navigate("/division/edit/" + tableMeta.rowData[0])
                  }
                >
                  <EditIcon color={"warning"} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Division">
                <IconButton
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to delete this record?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => {
                        // console.log("Deleted");
                        deleteDivisionFromDB(
                          tableMeta.rowData[0],
                          tableMeta.rowData[1]
                        );
                      },
                    });
                  }}
                >
                  <DeleteForeverIcon color={"error"} />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const HeaderElements = () => (
    <>
      <Tooltip title="Add Division">
        <IconButton onClick={() => navigate("/division/add")}>
          <AddCircleIcon
            color={"warning"}
            sx={{ fontSize: { xs: 26, sm: 32 } }}
          />
        </IconButton>
      </Tooltip>
    </>
  );

  const options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "vertical",
    // tableBodyHeight,
    // tableBodyMaxHeight,
    onTableChange: (action, state) => {
      // console.log(action);
      // console.dir(state);
    },
    rowsPerPage: 5,
    resizableColumns: false,
    rowsPerPageOptions: [5, 10, 15, 25, 50, 100, 150],
    jumpToPage: true,
    showLastButton: true,
    elevation: 0,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total Per Page",
        displayRows: "of",
        jumpToPage: "Page",
      },
      body: {
        noMatch: "No Division to display please Add Division",
      },
    },
    selectableRows: "none",
    customToolbar: () => <HeaderElements />,
  };
  let Content;
  if (isLoading) {
    return <Spinner />;
  }

  if (isError && error) {
    Content = (
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 mx-auto mt-2"></div>
          <div className="d-flex text-danger justify-content-center">
            <i className="bi bi-bar-chart-steps" style={{ fontSize: 50 }}></i>
          </div>
          <div className="d-flex justify-content-center">
            <h1 className="form-title">Division</h1>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-3">
            <h1 className="form-title">No Data</h1>
          </div>
        </div>
      </div>
    );
  }

  if (isSuccess && data) {
    let arrayValues = data.map((_data) => {
      return flattenObject(_data);
    });
    // console.log(arrayValues);
    let TableData = arrayValues.map((row) => {
      const updatedRow = {
        ...row,
        addDate: moment(row.createdAt).format("MMMM Do YYYY"),
        editDate: moment(row.updatedAt).format("MMMM Do YYYY"),
      };
      return updatedRow;
    });
    Content = (
      <div className="container-xxl container-fluid">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 mx-auto mt-2"></div>
          <div className="d-flex text-danger justify-content-center">
            <i className="bi bi-bar-chart-steps" style={{ fontSize: 50 }}></i>
          </div>
          <div className="d-flex justify-content-center">
            <h1 className="form-title">Division</h1>
          </div>
          <div className="mb-5  mui-table">
            {/*{JSON.stringify(standard)}*/}
            <CacheProvider value={muiCache}>
              <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  // title={""}
                  data={TableData}
                  columns={columns}
                  options={options}
                />
              </ThemeProvider>
            </CacheProvider>

            <Dialog open={confirmDialog.isOpen}>
              <DialogContent>
                <Typography variant="h6">{confirmDialog.title}</Typography>
                <Typography variant="subtitle2" color={"indigo"}>
                  {confirmDialog.subTitle}
                </Typography>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center", marginBottom: 2 }}>
                <Button
                  variant={"contained"}
                  color={"error"}
                  onClick={confirmDialog.onConfirm}
                  size={"small"}
                >
                  Yes
                </Button>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  size={"small"}
                  onClick={() =>
                    setConfirmDialog({ ...confirmDialog, isOpen: false })
                  }
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }

  const deleteDivisionFromDB = async (id, division) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    await deleteDivision(id)
      .unwrap()
      .then((payload) => {
        toast.success(
          `Successfully deleted Division ${division}`
          // `Successfully deleted Division ${JSON.stringify(payload)}`
        );
        navigate("/division");
      })
      .catch((error) =>
        toast.error(`Error deleting Division ${division} ${error.data.message}`)
      );
  };

  return <>{Content}</>;
};
export default DivisionComponent;
