import { apiSlice } from "../app/apiSlice";

// getAllDesignation,
// getSingleDesignation,
// addDesignation,
// updateDesignation,
// deleteDesignation,

export const designationAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllDesignation: builder.query({
      query: () => "/designation",
      providesTags: ["Designation"],
    }),
    getSingleDesignation: builder.query({
      query: (id) => `/designation/${id}`,
      providesTags: ["Designation"],
    }),
    addDesignation: builder.mutation({
      query: (designation) => ({
        url: `/designation`,
        method: "POST",
        body: designation,
      }),
      invalidatesTags: ["Designation"],
    }),
    updateDesignation: builder.mutation({
      query: ({ _id, ...rest }) => ({
        url: `/designation/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Designation"],
    }),
    deleteDesignation: builder.mutation({
      query: (id) => ({
        url: `/designation/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Designation"],
    }),
  }),
});

export const {
  useGetAllDesignationQuery,
  useGetSingleDesignationQuery,
  useAddDesignationMutation,
  useUpdateDesignationMutation,
  useDeleteDesignationMutation,
} = designationAPI;
