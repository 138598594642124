import { apiSlice } from "../app/apiSlice";

export const divisionAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllDivision: builder.query({
      query: () => "/division",
      providesTags: ["Division"],
    }),
    getSingleDivision: builder.query({
      query: (id) => `/division/${id}`,
      providesTags: ["Division"],
    }),
    addDivision: builder.mutation({
      query: (division) => ({
        url: "/division",
        method: "POST",
        body: division,
      }),
      invalidatesTags: ["Division"],
    }),
    updateDivision: builder.mutation({
      query: ({ _id, ...rest }) => ({
        url: `/division/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Division"],
    }),
    deleteDivision: builder.mutation({
      query: (id) => ({
        url: `/division/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Division"],
    }),
  }),
});

export const {
  useGetAllDivisionQuery,
  useGetSingleDivisionQuery,
  useAddDivisionMutation,
  useUpdateDivisionMutation,
  useDeleteDivisionMutation,
} = divisionAPI;
