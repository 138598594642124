import { apiSlice } from "../app/apiSlice";

// getAllStandard,
// getSingleStandard,
// addStandard,
// updateStandard,
// deleteStandard,

export const attendanceAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAttendancesByDateStandardDivision: builder.query({
      query: (arg) => {
        const { queryDate, queryStandard, queryDivision } = arg;
        return {
          url: `/attendance/day/${queryDate}/${queryStandard}/${queryDivision}`,
        };
      },
      providesTags: ["Attendance"],
    }),
    getAttendancesPerformanceByMonthStandardDivisionStudent: builder.query({
      query: (arg) => {
        const { performanceDate, standard, division, student } = arg;
        // console.log(performanceDate, standard, division, student);
        return {
          url: `/attendance/performanceStudent/${performanceDate}/${standard}/${division}/${student}`,
        };
      },
      providesTags: ["Attendance"],
    }),
    getAttendancesByStudentMonth: builder.query({
      query: (arg) => {
        const { date, standard, division, student } = arg;
        // console.log(std, div);
        return {
          url: `/attendance/monthStudent/${date}/${standard}/${division}/${student}`,
        };
      },
      providesTags: ["Attendance"],
    }),
    addBulkAttendance: builder.mutation({
      query: (data) => ({
        url: `/attendance/bulk`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Attendance"],
    }),
  }),
});

export const {
  useGetAttendancesByDateStandardDivisionQuery,
  useGetAttendancesByStudentMonthQuery,
  useGetAttendancesPerformanceByMonthStandardDivisionStudentQuery,
  useAddBulkAttendanceMutation,
} = attendanceAPI;
