import React from "react";
import ReactDOM from "react-dom/client";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter as Router } from "react-router-dom";
import NavbarComponent from "./components/layout/navbar/navbar.component";
import { Provider } from "react-redux";
import { store } from "./app/store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Router>
      <NavbarComponent />
      <App />
    </Router>
  </Provider>
);
