import { apiSlice } from "../app/apiSlice";

export const studentAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllStudent: builder.query({
      query: () => "/student",
      providesTags: ["Student"],
    }),
    getAllStudentsByStandardDivision: builder.query({
      query: (arg) => {
        const { std, div } = arg;
        // console.log(std, div);
        return {
          url: `/student/getby-std-div/${std}/${div}`,
        };
      },
      providesTags: ["Student"],
    }),
    getStudentsForAttendance: builder.query({
      query: (arg) => {
        const { std, div, date } = arg;
        // console.log(std, div);
        return {
          url: `/student/attendance/${std}/${div}/${date}`,
        };
      },
      providesTags: ["Student"],
    }),
    getEnrolledStudent: builder.query({
      query: () => "/student/enrolled",
      providesTags: ["Student"],
    }),
    getFormerStudent: builder.query({
      query: () => "/student/former",
      providesTags: ["Student"],
    }),
    getSingleStudent: builder.query({
      query: (id) => `/student/${id}`,
      providesTags: ["Student"],
    }),
    addStudent: builder.mutation({
      query: (student) => ({
        url: "/student",
        method: "POST",
        body: student,
      }),
      invalidatesTags: ["Student"],
    }),
    updateStudent: builder.mutation({
      query: ({ _id, ...rest }) => ({
        url: `/student/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Student"],
    }),
    deleteStudent: builder.mutation({
      query: (id) => ({
        url: `/student/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Student"],
    }),
  }),
});

export const {
  useGetAllStudentQuery,
  useGetAllStudentsByStandardDivisionQuery,
  useGetStudentsForAttendanceQuery,

  useGetEnrolledStudentQuery,
  useGetFormerStudentQuery,
  useGetSingleStudentQuery,
  useAddStudentMutation,
  useUpdateStudentMutation,
  useDeleteStudentMutation,
} = studentAPI;
