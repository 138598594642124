import { useNavigate } from "react-router-dom";
import { useChangePasswordMutation } from "../../services/authApi";
import { toast } from "react-toastify";
import { Button, InputAdornment, TextField } from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFormik } from "formik";
import * as yup from "yup";
import { auth_reset } from "../../app/authSlice";
import { apiSlice } from "../../app/apiSlice";
import { useDispatch, useSelector } from "react-redux";

const validationSchema = yup.object({
  oldPassword: yup
    .string()
    .matches(
      /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{6,}$/,
      "Password must contain at least one uppercase letter, one special character, and one number"
    )
    .required("Old Password is required"),
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{6,}$/,
      "Password must contain at least one uppercase letter, one special character, and one number"
    )
    .required("New Password is required"),
});

const ChangePasswordComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.auth);
  const [changePassword] = useChangePasswordMutation();
  const logout = () => {
    localStorage.removeItem("token");
    dispatch(auth_reset());
    dispatch(apiSlice.util.resetApiState());
    toast.success("Successfully logged out");
    navigate("/login");
  };
  const formik = useFormik({
    initialValues: {
      _id: data._id,
      oldPassword: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));
      changePassword(values)
        .unwrap()
        .then((payload) => {
          toast.success(`Password Changed successfully`);
          logout();
        })
        .catch((error) =>
          toast.error(`Error Changing Password : ${error.data.message}`)
        );
    },
  });

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-5">
            <div className="d-flex text-danger justify-content-center ">
              <i className="bi bi-key-fill" style={{ fontSize: 50 }}></i>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ marginLeft: "-12px" }}
        >
          <h1 className="form-title">Change Password</h1>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-3">
          <form onSubmit={formik.handleSubmit}>
            <TextField
              id={"oldPassword"}
              name="oldPassword"
              type="text"
              onChange={formik.handleChange}
              className="mb-4"
              label="Enter Old Password"
              fullWidth
              error={
                formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
              }
              helperText={
                formik.touched.oldPassword && formik.errors.oldPassword
              }
              value={formik.values.oldPassword}
              variant={"outlined"}
              InputProps={{
                // classes: { input: classes.input1 },
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmailIcon color={"success"} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              id={"password"}
              name="password"
              type="text"
              onChange={formik.handleChange}
              className="mb-4"
              label="Enter New Password"
              fullWidth
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              value={formik.values.password}
              variant={"outlined"}
              InputProps={{
                // classes: { input: classes.input1 },
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmailIcon color={"success"} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              size={"large"}
              variant="contained"
              // color="warning"

              fullWidth
              startIcon={<SaveIcon />}
              style={{ marginBottom: "15px", backgroundColor: "#522853" }}
            >
              Save
            </Button>
            <Button
              type="button"
              size={"large"}
              variant="contained"
              color="warning"
              onClick={() => navigate(-1)}
              fullWidth
              startIcon={<CancelIcon />}
              style={{ marginBottom: "15px" }}
            >
              Cancel
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};
export default ChangePasswordComponent;
