import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import AttendanceComponent from "./components/attendance/attendance.component";
import DivisionComponent from "./components/division/division.component";
import ResultManagerAdminComponent from "./components/result/resultManagerAdmin.component";
import SubjectComponent from "./components/subjects/subject.component";
import DashboardComponent from "./components/dashboard/dashboard.component";
import InvalidUrlComponent from "./components/not-found/invalid-url.component";
import HomeComponent from "./components/layout/home/home.component";
import LoginComponent from "./components/auth/login.component";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { useGetUserQuery } from "./services/authApi";
import { useEffect } from "react";
import { setUser } from "./app/authSlice";
import ViewStandardComponent from "./components/standard/viewStandard.component";
import StandardComponent from "./components/standard/standard.component";
import AddStandardComponent from "./components/standard/addStandard.component";
import AddDivisionComponent from "./components/division/addDivision.component";
import EditStandardComponent from "./components/standard/editStandard.component";
import AddSubjectsComponent from "./components/subjects/addSubjects.component";
import EditSubjectsComponent from "./components/subjects/editSubjects.component";
import ViewSubjectsComponent from "./components/subjects/viewSubjects.component";
import EditDivisionComponent from "./components/division/editDivision.component";
import ViewDivisionComponent from "./components/division/viewDivision.component";
import DesignationComponent from "./components/designation/designation.component";
import AddDesignationComponent from "./components/designation/addDesignation.component";
import ViewDesignationComponent from "./components/designation/viewDesignation.component";
import EditDesignationComponent from "./components/designation/editDesignation.component";
import UnauthorisedComponent from "./components/layout/unauthorised/unauthorised.component";
import UsersComponent from "./components/auth/users.component";
import AddUserComponent from "./components/auth/addUser.component";
import EditUserComponent from "./components/auth/editUser.component";
import ViewUserComponent from "./components/auth/viewUser.component";
import ChangePasswordComponent from "./components/auth/changePassword.component";
import ChangeUserPasswordComponent from "./components/auth/changeUserPassword.component";
import AllStudentsComponent from "./components/student/allStudents.component";
import EnrolledStudentsComponent from "./components/student/enrolledStudents.component";
import FormerStudentsComponent from "./components/student/formerStudents.component";
import AddStudentComponent from "./components/student/addStudent.component";
import EditStudentComponent from "./components/student/editStudent.component";
import ViewStudentComponent from "./components/student/viewStudent.component";
import AttendanceTableComponent from "./components/attendance/attendance-table.component";
import ViewAttendanceComponent from "./components/attendance/view-attendance.component";
import ViewStudentAttendanceMonthwiseComponent from "./components/attendance/view-student-attendance-monthwise.component";
import AddPerformanceComponent from "./components/performance/add-performance.component";
import PerformanceComponent from "./components/performance/performance.component";
import ViewPerformanceComponent from "./components/performance/view-performance.component";
import ViewPerformanceClassComponent from "./components/performance/view-performance-class.component";
import ResultTypeComponent from "./components/result-type/resultType.component";
import AddResultTypeComponent from "./components/result-type/addResultType.component";
import EditResultTypeComponent from "./components/result-type/editResultType.component";
import ViewResultTypeComponent from "./components/result-type/viewResultType.component";
import ResultManagerStaffComponent from "./components/result/resultManagerStaff.component";
import ResultMasterAdminComponent from "./components/result/resultMasterAdmin.component";
import ResultMasterStaffComponent from "./components/result/resultMasterStaff.component";
import ResultDetailsComponent from "./components/result/resultDetails.component";
import EditResultComponent from "./components/result/editResult.component";

const ProtectedRoute = () => {
  if (!localStorage.getItem("token")) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
};

function App() {
  const dispatch = useDispatch();
  const { data, isSuccess, isError, error } = useGetUserQuery();

  useEffect(() => {
    if (localStorage.getItem("token")) {
      if (isSuccess && data) {
        const _token = localStorage.getItem("token");
        const user = {
          _id: data._id,
          firstname: data.firstname,
          lastname: data.lastname,
          email: data.email,
          designation: data.designation,
          fullname: data.firstname + " " + data.lastname,
          token: _token,
        };
        dispatch(setUser(user));
      }
      if (isError && error) {
        localStorage.removeItem("token");
      }
    }
  });
  // Functionality to remove token from local storage if users close app without logout
  // useEffect(() => {
  //   const handleBeforeUnload = () => {
  //     // Remove the token from local storage
  //     localStorage.removeItem('token');
  //   };
  //
  //   // Add the event listener when the component mounts
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //
  //   // Clean up the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);
  return (
    <>
      <CssBaseline />
      <ToastContainer position="top-right" theme="colored" autoClose={900} />

      <Routes>
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/" element={<HomeComponent />} />
        <Route
          path="/auth-error/:message"
          element={<UnauthorisedComponent />}
        />
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<DashboardComponent />} />
          <Route path="/performance" element={<PerformanceComponent />} />
          <Route
            path="/performance/view/:performanceDate/:standard/:division/:student"
            element={<ViewPerformanceComponent />}
          />
          <Route
            path="/performance/viewByClass/:performanceDate/:standard/:division"
            element={<ViewPerformanceClassComponent />}
          />
          <Route
            path="/addPerformance/:performanceDate/:standard/:division/:student"
            element={<AddPerformanceComponent />}
          />
          <Route path="/attendance" element={<AttendanceComponent />} />
          <Route
            path="/attendance/:std/:div/:attendanceDate"
            element={<AttendanceTableComponent />}
          />
          <Route
            path="/attendance/viewByStudent/:date/:standard/:division/:student"
            element={<ViewStudentAttendanceMonthwiseComponent />}
          />
          <Route
            path="/attendance/view/:date/:standard/:division"
            element={<ViewAttendanceComponent />}
          />
          <Route path="/division" element={<DivisionComponent />} />
          <Route path="/division/add" element={<AddDivisionComponent />} />
          <Route
            path="/division/edit/:id"
            element={<EditDivisionComponent />}
          />
          <Route
            path="/division/view/:id"
            element={<ViewDivisionComponent />}
          />
          <Route
            path="/result-admin"
            element={<ResultManagerAdminComponent />}
          />
          <Route
            path="/result-details/:id/:standard/:division/:status/:resultType"
            element={<ResultDetailsComponent />}
          />
          <Route
            path="/result-master"
            element={<ResultMasterAdminComponent />}
          />
          <Route
            path="/result-staff/:result_master/:standard/:division"
            element={<ResultManagerStaffComponent />}
          />
          <Route
            path="/resultMasterStaff"
            element={<ResultMasterStaffComponent />}
          />
          <Route
            path="/editResult/:studentID/:resultMasterID/:student/:resultType"
            element={<EditResultComponent />}
          />
          <Route path="/result-type" element={<ResultTypeComponent />} />
          <Route path="/result-type/add" element={<AddResultTypeComponent />} />
          <Route
            path="/result-type/edit/:id"
            element={<EditResultTypeComponent />}
          />
          <Route
            path="/result-type/view/:id"
            element={<ViewResultTypeComponent />}
          />
          <Route path="/standard" element={<StandardComponent />} />
          <Route path="/standard/add" element={<AddStandardComponent />} />
          <Route
            path="/standard/edit/:id"
            element={<EditStandardComponent />}
          />
          <Route
            path="/standard/view/:id"
            element={<ViewStandardComponent />}
          />
          <Route path="/students" element={<AllStudentsComponent />} />
          <Route
            path="/students/enrolled"
            element={<EnrolledStudentsComponent />}
          />
          <Route
            path="/students/former"
            element={<FormerStudentsComponent />}
          />
          <Route path="/students/add" element={<AddStudentComponent />} />
          <Route path="/students/edit/:id" element={<EditStudentComponent />} />
          <Route path="/students/view/:id" element={<ViewStudentComponent />} />
          <Route path="/subjects" element={<SubjectComponent />} />
          <Route path="/subjects/add" element={<AddSubjectsComponent />} />
          <Route
            path="/subjects/view/:id"
            element={<ViewSubjectsComponent />}
          />
          <Route
            path="/subjects/edit/:id"
            element={<EditSubjectsComponent />}
          />
          <Route path="/designation" element={<DesignationComponent />} />
          <Route
            path="/designation/add"
            element={<AddDesignationComponent />}
          />
          <Route
            path="/designation/view/:id"
            element={<ViewDesignationComponent />}
          />
          <Route
            path="/designation/edit/:id"
            element={<EditDesignationComponent />}
          />
          <Route path="/users" element={<UsersComponent />} />
          <Route path="/users/add" element={<AddUserComponent />} />
          <Route path="/users/edit/:id" element={<EditUserComponent />} />
          <Route path="/users/view/:id" element={<ViewUserComponent />} />
          <Route
            path="/users/change-password"
            element={<ChangePasswordComponent />}
          />
          <Route
            path="/users/change-user-password/:id"
            element={<ChangeUserPasswordComponent />}
          />
          <Route path="*" element={<InvalidUrlComponent />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
