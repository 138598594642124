import { useNavigate, useParams } from "react-router-dom";
import { useGetStudentsForAttendanceQuery } from "../../services/studentAPI";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { useAddBulkAttendanceMutation } from "../../services/attendanceAPI";
import moment from "moment";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";
import HeaderComponent from "../../shared/header.component";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import Spinner from "../../shared/Spinner";

const AttendanceTableComponent = () => {
  const params = useParams();
  let Content;
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth);
  const std = params.std;
  const div = params.div;
  const date = params.attendanceDate;

  const { data, isSuccess, isError, error, isLoading } =
    useGetStudentsForAttendanceQuery({
      std,
      div,
      date,
    });
  const [addBulkAttendance] = useAddBulkAttendanceMutation();
  const [attendanceData, setAttendanceData] = useState([]);
  const handleCheckboxChange = (e, rowIndex) => {
    const updatedTableData = [...attendanceData];
    updatedTableData[rowIndex].attendance = e.target.checked;
    setAttendanceData(updatedTableData);
  };

  useEffect(() => {
    if (isSuccess && data) {
      // console.log(data);
      let modData = [];
      // eslint-disable-next-line
      data.map((student) => {
        modData.push({
          attendanceDate: params.attendanceDate,
          student: student._id,
          studentName: student.fullname,
          admissionNo: student.admissionNo,
          standard: student.standard._id,
          division: student.division._id,
          standardName: student.standard.standard,
          divisionName: student.division.division,
          attendance: true,
          user: userData._id,
        });
      });
      setAttendanceData(modData);
      // console.log(modData);
    }
    // eslint-disable-next-line
  }, [isSuccess, data]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isSuccess && data) {
    Content = (
      <>
        <div className="container">
          <div className="row">
            <HeaderComponent
              title={`Attendance : ${moment(params.attendanceDate).format(
                "DD/MM/YYYY"
              )}`}
              icon="bi bi-calendar-check-fill"
            />
          </div>
          <form onSubmit={submitForm}>
            <table className="table  table-striped table-bordered table-hover table-responsive-sm ">
              <thead style={{ backgroundColor: "#FE5632", color: "white" }}>
                <tr>
                  <th scope="col" className="tbHead">
                    #
                  </th>
                  <th scope="col" className="tbHead">
                    Student
                  </th>
                  <th scope="col" className="tbHead">
                    Standard
                  </th>
                  <th scope="col" className="tbHead">
                    Division
                  </th>
                  <th scope="col" className="tbHead">
                    Present
                  </th>
                </tr>
              </thead>
              <tbody>
                {attendanceData &&
                  attendanceData?.map((row, rowIndex) => {
                    let serial = rowIndex + 1;
                    return (
                      <tr key={rowIndex}>
                        <th className="tbCell">{serial}</th>
                        <th className="tbCell">{row.studentName}</th>
                        <th className="tbCell">{row.standardName}</th>
                        <th className="tbCell">{row.divisionName}</th>
                        <th className="tbCell">
                          <input
                            className="form-check-input "
                            type="checkbox"
                            role="switch"
                            id="flexSwitchCheckChecked"
                            checked={row.attendance}
                            onChange={(e) => handleCheckboxChange(e, rowIndex)}
                          />
                        </th>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="row mb-5 mt-2">
              <div className="col-md-6">
                <Button
                  type="submit"
                  size={"large"}
                  variant="contained"
                  // color="warning"

                  fullWidth
                  startIcon={<SaveIcon />}
                  style={{ marginBottom: "15px", backgroundColor: "#522853" }}
                >
                  Submit Attendance
                </Button>
              </div>
              <div className="col-md-6">
                <Button
                  type="button"
                  size={"large"}
                  variant="contained"
                  color="warning"
                  onClick={() => navigate(-1)}
                  fullWidth
                  startIcon={<CancelIcon />}
                  style={{ marginBottom: "15px" }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }

  if (isError && error) {
    Content = (
      <>
        <div className="container">
          <div className="row">
            <HeaderComponent
              title={`Attendance : ${moment(params.attendanceDate).format(
                "DD/MM/YYYY"
              )}`}
              icon="bi bi-calendar-check-fill"
            />
          </div>
          <h3 className="sub-title text-center text-danger mt-2">
            Attendance already taken...
          </h3>

          <div className="row mb-5 mt-4">
            <div className="col-md-2 col-6 mx-auto">
              <Button
                type="button"
                size={"large"}
                variant="contained"
                color="secondary"
                onClick={() => navigate(-1)}
                fullWidth
                startIcon={<FastRewindIcon />}
                style={{ marginBottom: "15px" }}
              >
                Go Back
              </Button>
            </div>
          </div>
        </div>
      </>
    );
    toast.error(`Error getting Attendance : ${error.data.message}`);
  }

  function submitForm(e) {
    e.preventDefault();
    addBulkAttendance(attendanceData)
      .unwrap()
      .then((payload) => {
        // console.log(payload);
        const date = new Date(params.attendanceDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const day = String(date.getDate()).padStart(2, "0");
        const formattedDate = `${year}-${month}-${day}`;

        // console.log(formattedDate);
        toast.success(
          `Attendance taken successfully Date:${moment(
            params.attendanceDate
          ).format("DD/MM/YYYY")}`
        );
        navigate(
          `/attendance/view/${formattedDate}/${params.std}/${params.div}`
        );
      })
      .catch((error) => {
        toast.error(`Error taking Attendance : ${error.data.message}`);
        navigate(`/attendance`);
      });
    // console.log(attendanceData);
  }

  return Content;
};
export default AttendanceTableComponent;
