import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const useAdminAuth = (message) => {
  const navigate = useNavigate();
  const authState = useSelector((state) => state.auth);
  // console.log('AuthState', authState)
  // console.log(message)
  useEffect(() => {
    if (!authState || authState.designation !== "Admin") {
      navigate(`/auth-error/${message}`);
    }
  }, [authState, navigate, message]);

  return authState;
};

export default useAdminAuth;
