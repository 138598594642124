import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useUpdateUserMutation } from "../../services/authApi";
import { GetSingleUserFromStore } from "../../services/storeAccessAPI";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import FormControl from "@mui/material/FormControl";
import {
  Button,
  FormHelperText,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useGetAllDesignationQuery } from "../../services/designationAPI";

const validationSchema = yup.object({
  firstname: yup.string().required("Please enter your first name"),
  lastname: yup.string().required("Please enter your last name"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please enter your email"),
  designation: yup.string().required("Please select a designation"),
  status: yup.boolean(),
});

const EditUserComponent = () => {
  const params = useParams();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [designation, setDesignation] = useState();
  const [userData, setUserData] = useState({
    _id: "",
    firstname: "",
    lastname: "",
    email: "",
    designation: "",
    isActive: false,
  });
  const { data: designationData, isSuccess: designationIsSuccess } =
    useGetAllDesignationQuery();
  const dispatch = useDispatch();
  const [editUser] = useUpdateUserMutation();

  useEffect(() => {
    function fetchData() {
      if (designationIsSuccess && designationData) {
        // console.log(designationData);
        setDesignation(designationData.designation);
      }
    }

    fetchData();
  }, [designationIsSuccess, designationData]);

  useEffect(() => {
    GetSingleUserFromStore(params.id, dispatch)
      .then((data) => {
        // console.log(data.data);
        setUserData({
          ...userData,
          _id: data.data._id,
          firstname: data.data.firstname,
          lastname: data.data.lastname,
          email: data.data.email,
          designation: data.data.designation._id,
          isActive: data.data.isActive,
        });
        // console.log(userData);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, [dispatch, params.id]);

  const formik = useFormik({
    initialValues: userData,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log("Form submitted", values);
      // console.log(JSON.stringify(values, null, 2));
      editUser(values)
        .unwrap()
        .then((payload) => {
          toast.success(`User updated successfully`);
          navigate("/users");
        })
        .catch((error) =>
          toast.error(`Error updating User error.data.message}`)
        );
    },
  });

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-2">
            <div className="d-flex text-danger justify-content-center ">
              <i className="bi bi-people-fill" style={{ fontSize: 50 }}></i>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ marginLeft: "-12px" }}
        >
          <h1 className="form-title">Edit User</h1>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-3">
          <form onSubmit={formik.handleSubmit}>
            <TextField
              name="firstname"
              type="text"
              onChange={formik.handleChange}
              label="First Name"
              error={
                formik.touched.firstname && Boolean(formik.errors.firstname)
              }
              helperText={formik.touched.firstname && formik.errors.firstname}
              value={formik.values.firstname}
              placeholder="Enter First Name"
              onFocus={formik.handleBlur}
              className="mb-4"
              fullWidth
              variant={"outlined"}
              InputProps={{
                // classes: { input: classes.input1 },
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmailIcon color={"success"} />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              name="lastname"
              type="text"
              onChange={formik.handleChange}
              label="Last Name"
              error={formik.touched.lastname && Boolean(formik.errors.lastname)}
              helperText={formik.touched.lastname && formik.errors.lastname}
              value={formik.values.lastname}
              placeholder="Enter Last Name"
              onFocus={formik.handleBlur}
              className="mb-4"
              fullWidth
              variant={"outlined"}
              InputProps={{
                // classes: { input: classes.input1 },
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmailIcon color={"success"} />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              name="email"
              type="text"
              onChange={formik.handleChange}
              label="Email"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              value={formik.values.email}
              placeholder="Enter Email"
              onFocus={formik.handleBlur}
              className="mb-4"
              fullWidth
              variant={"outlined"}
              InputProps={{
                // classes: { input: classes.input1 },
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmailIcon color={"success"} />
                  </InputAdornment>
                ),
              }}
            />

            <FormControl fullWidth={true} className="mb-3">
              <InputLabel id="demo-simple-select-label" shrink={true}>
                Designation
              </InputLabel>
              <Select
                name="designation"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                error={
                  formik.touched.designation &&
                  Boolean(formik.errors.designation)
                }
                // helperText={
                //   formik.touched.designation && formik.errors.designation
                // }
                value={formik.values.designation}
                label="Designation"
                defaultValue="choose"
                onChange={formik.handleChange}
              >
                {designationData?.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.designation}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText style={{ color: "red" }}>
                {formik.touched.designation && formik.errors.designation}
              </FormHelperText>
            </FormControl>

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                    name="isActive"
                  />
                }
                label={formik.values.isActive ? "Active" : "Disabled"}
              />
            </FormGroup>

            <Button
              type="submit"
              size={"large"}
              variant="contained"
              // color="warning"

              fullWidth
              startIcon={<SaveIcon />}
              style={{ marginBottom: "15px", backgroundColor: "#522853" }}
            >
              Save
            </Button>
            <Button
              type="button"
              size={"large"}
              variant="contained"
              color="warning"
              onClick={() => navigate(-1)}
              fullWidth
              startIcon={<CancelIcon />}
              style={{ marginBottom: "15px" }}
            >
              Cancel
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};
export default EditUserComponent;
