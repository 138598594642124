import HeaderComponent from "../../shared/header.component";
import { useEffect, useState } from "react";
import useAdminAuth from "../../hooks/useAdminAuth";
import { useNavigate } from "react-router-dom";
import { useGetAllStandardQuery } from "../../services/standardAPI";
import { useGetAllDivisionQuery } from "../../services/divisionAPI";
import { useFormik } from "formik";
import * as yup from "yup";
import { disableSunday, formatDate } from "../../utils/sharedFunctions";
import { toast } from "react-toastify";
import FormControl from "@mui/material/FormControl";
import { Button, FormHelperText, InputLabel, TextField } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { useGetAllResultTypeQuery } from "../../services/resultTypeAPI";
import { useAddResultMutation } from "../../services/resultAPI";
import Spinner from "../../shared/Spinner";

const validationSchema = yup.object({
  standard: yup.string().required("Standard name is required"),
  division: yup.string().required("Division name is required"),
  result_type: yup.string().required("Result Type is required"),
  result_date: yup.date().required("Result Date is required"),
});

const ResultManagerAdminComponent = () => {
  const authState = useAdminAuth("Result Manager Admin");
  const navigate = useNavigate();
  const [standardData, setStandardData] = useState();
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  // const dispatch = useDispatch();
  const [divisionData, setDivisionData] = useState();
  // eslint-disable-next-line
  const [formData, setFormData] = useState({
    standard: "",
    division: "",
    result_type: "",
    result_date: "",
  });
  const [divisionFilteredData, setDivisionFilteredData] = useState();
  const { data: StandardData, isSuccess: StandardIsSuccess } =
    useGetAllStandardQuery();
  const { data: DivisionData, isSuccess: DivisionIsSuccess } =
    useGetAllDivisionQuery();
  // eslint-disable-next-line
  const { data: result_TypeData, isSuccess: result_TypeIsSuccess } =
    useGetAllResultTypeQuery();
  const [addResult] = useAddResultMutation();
  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.result_date = formatDate(values.result_date);
      console.log(JSON.stringify(values, null, 2));
      setLoading(true);
      addResult(values)
        .unwrap()
        .then((payload) => {
          setLoading(false);
          toast.success(`Result generated successfully`);
          formik.resetForm();
          navigate("/result-master");
        })
        .catch((error) => {
          setLoading(false);
          // console.log(error.data);
          toast.error(`Error generating Result : ${error.data.message}`);
        });
    },
  });
  useEffect(() => {
    function getStandard() {
      if (StandardIsSuccess && StandardData) {
        setStandardData(StandardData);
        // console.log(standardData);
      }
    }

    getStandard();
  }, [StandardIsSuccess, StandardData, standardData]);

  useEffect(() => {
    function getDivision() {
      if (DivisionIsSuccess && DivisionData) {
        // console.log(DivisionData);
        setDivisionData(DivisionData);

        let filteredData = getDivisionsForStandard(formik.values.standard);

        setDivisionFilteredData(filteredData);
        // console.log(filteredData);
      }
    }

    getDivision();
    // eslint-disable-next-line
  }, [DivisionIsSuccess, DivisionData, formik.values.standard]);

  const getDivisionsForStandard = (standard) => {
    const filteredDivisions = divisionData?.filter(
      (division) => division.standard._id === standard
    );
    return filteredDivisions;
  };
  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {authState && authState.designation === "Admin" && (
        <div className="container">
          <div className="row">
            <HeaderComponent
              title="Result Manager Admin"
              icon="bi bi-clipboard-data-fill"
            />

            <form onSubmit={formik.handleSubmit} className="mt-2 ">
              <div className="row">
                <div className="col-md-6 col-lg-2">
                  <FormControl fullWidth={true} className="mb-4 ">
                    <InputLabel id="demo-simple-select-label">
                      Standard
                    </InputLabel>
                    <Select
                      name="standard"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      error={
                        formik.touched.standard &&
                        Boolean(formik.errors.standard)
                      }
                      value={formik.values.standard}
                      label="Standard"
                      defaultValue="choose"
                      onChange={formik.handleChange}
                    >
                      <MenuItem disabled value="choose">
                        Select Standard
                      </MenuItem>
                      {standardData?.map((option) => (
                        <MenuItem key={option._id} value={option._id}>
                          {option.standard}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {formik.touched.standard && formik.errors.standard}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-md-6 col-lg-2">
                  <FormControl fullWidth={true} className="mb-4">
                    <InputLabel id="demo-simple-select-label">
                      Division
                    </InputLabel>
                    <Select
                      name="division"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      error={
                        formik.touched.division &&
                        Boolean(formik.errors.division)
                      }
                      value={formik.values.division}
                      label="Division"
                      defaultValue="choose"
                      onChange={formik.handleChange}
                    >
                      <MenuItem disabled value="choose">
                        Select Standard
                      </MenuItem>
                      {divisionFilteredData?.length > 0 &&
                        divisionFilteredData?.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.division}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {formik.touched.division && formik.errors.division}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-md-6 col-lg-3">
                  <FormControl fullWidth={true} className="mb-4 ">
                    <InputLabel id="demo-simple-select-label">
                      Result Type
                    </InputLabel>
                    <Select
                      name="result_type"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      error={
                        formik.touched.result_type &&
                        Boolean(formik.errors.result_type)
                      }
                      value={formik.values.result_type}
                      label="Student"
                      defaultValue="choose"
                      onChange={formik.handleChange}
                    >
                      <MenuItem disabled value="choose">
                        Select Student
                      </MenuItem>
                      {result_TypeData?.length > 0 &&
                        result_TypeData?.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.result_type}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText style={{ color: "red" }}>
                      {formik.touched.result_type && formik.errors.result_type}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-md-6 col-lg-2">
                  <FormControl fullWidth={true} className="mb-4">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Result Date"
                        id="result_date"
                        name="result_date"
                        // views={["year", "month"]}
                        inputFormat="DD/MM/YYYY"
                        // maxDate={new Date()+15}
                        shouldDisableDate={disableSunday}
                        value={formik.values.result_date}
                        onChange={(value) => {
                          formik.setFieldValue("result_date", value, true);
                          // formik.setFieldValue("result_date", Date.parse(value));
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                    <FormHelperText style={{ color: "red" }}>
                      {formik.touched.result_date && formik.errors.result_date}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-md-12 col-lg-3 mb-3">
                  <Button
                    type="button"
                    size={"small"}
                    variant="contained"
                    color="error"
                    onClick={formik.handleSubmit}
                    fullWidth
                    style={{ height: "55px" }}
                    startIcon={<i className="bi bi-bar-chart-fill"></i>}
                    // style={{ marginBottom: "15px" }}
                  >
                    Generate Result
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ResultManagerAdminComponent;
