import moment from "moment/moment";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../shared/Spinner";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { toast } from "react-toastify";
import { useGetAttendancesByStudentMonthQuery } from "../../services/attendanceAPI";
import HeaderComponent from "../../shared/header.component";

const ViewStudentAttendanceMonthwiseComponent = () => {
  const params = useParams();
  const navigate = useNavigate();
  // console.log(params);

  const date = params.date;
  const standard = params.standard;
  const division = params.division;
  const student = params.student;
  const [studentFullName, setStudentFullName] = useState("");
  // eslint-disable-next-line
  const [totalDays, setTotalDays] = useState();
  // eslint-disable-next-line
  const [daysAbsent, setDaysAbsent] = useState();
  // eslint-disable-next-line
  const [attendancePercentage, setAttendancePercentage] = useState(0);
  const [attendanceData, setAttendanceData] = useState([]);
  const { data, loading, isSuccess, isError, error } =
    useGetAttendancesByStudentMonthQuery({
      date,
      standard,
      division,
      student,
    });

  useEffect(() => {
    if (isSuccess && data) {
      // console.log(data);
      setAttendanceData(data);
      setTotalDays(data.length);
      // eslint-disable-next-line
      data.map((data) => {
        let FullName = `${data.student.firstName} ${data.student.middleName} ${data.student.lastName}`;
        setStudentFullName(FullName);
      });
      const absentDate = data.filter((item) => item.attendance !== true).length;
      // console.log(dt);
      setDaysAbsent(absentDate);
      const percentage =
        ((parseInt(totalDays) - parseInt(daysAbsent)) / parseInt(totalDays)) *
        100;
      const percent = parseInt(percentage.toFixed(2));
      setAttendancePercentage(percent);
    }
    // eslint-disable-next-line
  }, [isSuccess, data, attendancePercentage]);

  if (loading) {
    return <Spinner />;
  }
  if (isError && error) {
    toast.error(`Error getting Attendance : ${error.data.message}`);
  }

  return (
    <div className="container">
      <div className="row">
        <HeaderComponent
          title={`${studentFullName}: ${moment(params.date).format("MMMM")}`}
          icon="bi bi-calendar-check-fill"
        />
      </div>
      <h5 className="text-secondary text-center sub-title">
        Total Days: {totalDays} Absent: {daysAbsent} day [{attendancePercentage}
        %]
      </h5>
      <table className="table  table-striped table-bordered table-hover table-responsive-sm ">
        <thead style={{ backgroundColor: "#FE5632", color: "white" }}>
          <tr>
            <th scope="col" className="tbHead">
              #
            </th>
            <th scope="col" className="tbHead">
              Date
            </th>
            <th scope="col" className="tbHead">
              Standard
            </th>
            <th scope="col" className="tbHead">
              Division
            </th>
            <th scope="col" className="tbHead">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {attendanceData.length > 0 &&
            attendanceData?.map((row, rowIndex) => {
              let serial = rowIndex + 1;
              return (
                <tr key={rowIndex}>
                  <th className="tbCell">{serial}</th>
                  <th className="tbCell">
                    {moment(row.attendanceDate).format("DD/MM/YYYY")}
                  </th>
                  <th className="tbCell">{row.standard.standard}</th>
                  <th className="tbCell">{row.division.division}</th>
                  <th className="tbCell">
                    {row.attendance ? "Present" : "Absent"}
                  </th>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="row mb-5 mt-2">
        <div className="col-md-6">
          <Button
            type="button"
            size={"large"}
            variant="contained"
            color="warning"
            onClick={() => navigate("/attendance")}
            fullWidth
            startIcon={<EventAvailableIcon />}
            style={{ marginBottom: "15px", backgroundColor: "#522853" }}
          >
            Back to Attendance
          </Button>
        </div>
        <div className="col-md-6">
          <Button
            type="button"
            size={"large"}
            variant="contained"
            color="warning"
            onClick={() => navigate("/")}
            fullWidth
            startIcon={<HomeIcon />}
            style={{ marginBottom: "15px" }}
          >
            Back to Home
          </Button>
        </div>
      </div>
    </div>
  );
};
export default ViewStudentAttendanceMonthwiseComponent;
