import { apiSlice } from "../app/apiSlice";

// getAllSubject,
// getSingleSubject,
// addSubject,
// updateSubject,
// deleteSubject,

export const subjectAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllSubject: builder.query({
      query: () => "/subject",
      providesTags: ["Subject"],
    }),
    getSingleSubject: builder.query({
      query: (id) => `/subject/${id}`,
      providesTags: ["Subject"],
    }),
    addSubject: builder.mutation({
      query: (subject) => ({
        url: "/subject",
        method: "POST",
        body: subject,
      }),
      invalidatesTags: ["Subject"],
    }),
    updateSubject: builder.mutation({
      query: ({ _id, ...rest }) => ({
        url: `/subject/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Subject"],
    }),
    deleteSubject: builder.mutation({
      query: (id) => ({
        url: `/subject/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Subject"],
    }),
  }),
});

export const {
  useGetAllSubjectQuery,
  useGetSingleSubjectQuery,
  useAddSubjectMutation,
  useUpdateSubjectMutation,
  useDeleteSubjectMutation,
} = subjectAPI;
