import FormControl from "@mui/material/FormControl";
import { Button, FormHelperText, InputLabel, TextField } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useCallback, useEffect, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { useGetAllStandardQuery } from "../../services/standardAPI";
import { useGetAllDivisionQuery } from "../../services/divisionAPI";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { disableSunday, formatDate } from "../../utils/sharedFunctions";
import { GetAllStudentByStandardDivision } from "../../services/storeAccessAPI";
import { useDispatch } from "react-redux";
import HeaderComponent from "../../shared/header.component";

const validationSchema = yup.object({
  standard: yup.string().required("Standard name is required"),
  division: yup.string().required("Division name is required"),
  attendanceDate: yup.date().required("Attendance Date is required"),
});
const validation3Schema = yup.object({
  standard: yup.string().required("Standard name is required"),
  division: yup.string().required("Division name is required"),
  attendanceDate: yup.date().required("Attendance Date is required"),
  student: yup.string().required("Student is required"),
});

const AttendanceComponent = () => {
  const navigate = useNavigate();
  const [standardData, setStandardData] = useState();
  const [divisionData, setDivisionData] = useState();
  // eslint-disable-next-line
  const [studentsData, setStudentsData] = useState();
  // eslint-disable-next-line
  const [formData, setFormData] = useState({
    standard: "",
    division: "",
    attendanceDate: "",
  });
  // eslint-disable-next-line
  const [form2Data, setForm2Data] = useState({
    standard: "",
    division: "",
    attendanceDate: "",
  });
  // eslint-disable-next-line
  const [form3Data, setForm3Data] = useState({
    standard: "",
    division: "",
    attendanceDate:"",
    student: "",
  });

  const [divisionFilteredData, setDivisionFilteredData] = useState();
  const { data: StandardData, isSuccess: StandardIsSuccess } =
    useGetAllStandardQuery();
  const { data: DivisionData, isSuccess: DivisionIsSuccess } =
    useGetAllDivisionQuery();

  const formik = useFormik({
    initialValues: formData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));
      values.attendanceDate = formatDate(values.attendanceDate);
      // console.log(values.attendanceDate);
      navigate(
        `/attendance/${values.standard}/${values.division}/${values.attendanceDate}`
      );
    },
  });
  const formik2 = useFormik({
    initialValues: form2Data,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));
      values.attendanceDate = formatDate(values.attendanceDate);
      // console.log(formattedDate);
      navigate(
        `/attendance/view/${values.attendanceDate}/${values.standard}/${values.division}`
      );
    },
  });
  const formik3 = useFormik({
    initialValues: form3Data,
    validationSchema: validation3Schema,
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));
      values.attendanceDate = formatDate(values.attendanceDate);
      console.log(
        `Date: ${values.attendanceDate} Standard: ${values.standard} Division: ${values.division} Student: ${values.student}`
      );

      // console.log(formattedDate);
      navigate(
        `/attendance/viewByStudent/${values.attendanceDate}/${values.standard}/${values.division}/${values.student}`
      );
    },
  });

  useEffect(() => {
    function getStandard() {
      if (StandardIsSuccess && StandardData) {
        setStandardData(StandardData);
        // console.log(standardData);
      }
    }

    getStandard();
  }, [StandardIsSuccess, StandardData, standardData]);

  useEffect(() => {
    function getDivision() {
      if (DivisionIsSuccess && DivisionData) {
        // console.log(DivisionData);
        setDivisionData(DivisionData);

        let filteredData = getDivisionsForStandard(formik.values.standard);

        setDivisionFilteredData(filteredData);
        // console.log(filteredData);
      }
    }

    getDivision();
    // eslint-disable-next-line
  }, [DivisionIsSuccess, DivisionData, formik.values.standard]);

  useEffect(() => {
    function getDivision() {
      if (DivisionIsSuccess && DivisionData) {
        // console.log(DivisionData);
        setDivisionData(DivisionData);

        let filteredGenerateData = getDivisionsForStandard(
          formik2.values.standard
        );

        setDivisionFilteredData(filteredGenerateData);
        // console.log(filteredData);
      }
    }

    getDivision();
    // eslint-disable-next-line
  }, [DivisionIsSuccess, DivisionData, formik2.values.standard]);

  useEffect(() => {
    function getDivision() {
      if (DivisionIsSuccess && DivisionData) {
        // console.log(DivisionData);
        setDivisionData(DivisionData);

        let filteredGenerateData = getDivisionsForStandard(
          formik3.values.standard
        );

        setDivisionFilteredData(filteredGenerateData);
        // console.log(filteredData);
      }
    }

    getDivision();
    // eslint-disable-next-line
  }, [DivisionIsSuccess, DivisionData, formik3.values.standard]);

  const getDivisionsForStandard = (standard) => {
    const filteredDivisions = divisionData?.filter(
      (division) => division.standard._id === standard
    );
    return filteredDivisions;
  };
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const getStudentData = useCallback(() => {
    if (formik3.values.standard && formik3.values.division) {
      setLoading(true);
      GetAllStudentByStandardDivision(
        formik3.values.standard,
        formik3.values.division,
        dispatch
      )
        .then((data) => {
          setLoading(false);
          // console.log(data);
          setStudentsData(data.data);
          console.log(studentsData.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    // eslint-disable-next-line
  }, [formik3.values.standard, formik3.values.division, dispatch]);

  useEffect(() => {
    getStudentData();
  }, [getStudentData]);

  return (
    <div className="container">
      <div className="row">
        <HeaderComponent
          title="Attendance Manager"
          icon="bi bi-calendar-check-fill"
        />
        <form onSubmit={formik.handleSubmit} className="mt-4 ">
          <div className="row justify-content-between">
            <div className="col-md-6 col-lg-3">
              <FormControl fullWidth={true} className="mb-4 me-2">
                <InputLabel id="demo-simple-select-label">Standard</InputLabel>
                <Select
                  name="standard"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  error={
                    formik.touched.standard && Boolean(formik.errors.standard)
                  }
                  value={formik.values.standard}
                  label="Standard"
                  defaultValue="choose"
                  onChange={formik.handleChange}
                >
                  <MenuItem disabled value="choose">
                    Select Standard
                  </MenuItem>
                  {standardData?.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.standard}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {formik.touched.standard && formik.errors.standard}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-md-6 col-lg-3">
              <FormControl fullWidth={true} className="mb-4 me-2">
                <InputLabel id="demo-simple-select-label">Division</InputLabel>
                <Select
                  name="division"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  error={
                    formik.touched.division && Boolean(formik.errors.division)
                  }
                  value={formik.values.division}
                  label="Division"
                  defaultValue="choose"
                  onChange={formik.handleChange}
                >
                  <MenuItem disabled value="choose">
                    Select Standard
                  </MenuItem>
                  {divisionFilteredData?.length > 0 &&
                    divisionFilteredData?.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.division}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {formik.touched.division && formik.errors.division}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-md-6 col-lg-3">
              <FormControl fullWidth={true} className="mb-4 me-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Attendance Date"
                    id="attendanceDate"
                    name="attendanceDate"
                    inputFormat="DD/MM/YYYY"
                    maxDate={new Date()}
                    shouldDisableDate={disableSunday}
                    value={formik.values.attendanceDate}
                    onChange={(value) => {
                      formik.setFieldValue("attendanceDate", value, true);
                      // formik.setFieldValue("attendanceDate", Date.parse(value));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText style={{ color: "red" }}>
                  {formik.touched.attendanceDate &&
                    formik.errors.attendanceDate}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-md-6 col-lg-3">
              <Button
                type="button"
                size={"small"}
                variant="contained"
                color="info"
                onClick={formik.handleSubmit}
                fullWidth
                style={{ height: "55px", marginBottom: "15px" }}
                startIcon={<i className="bi bi-building-check"></i>}
                // style={{ marginBottom: "15px" }}
              >
                Generate Attendance
              </Button>
            </div>
          </div>
        </form>
        <form onSubmit={formik2.handleSubmit} className="mt-2 ">
          <div className="row justify-content-between">
            <div className="col-md-6 col-lg-3">
              <FormControl fullWidth={true} className="mb-4 me-2">
                <InputLabel id="demo-simple-select-label">Standard</InputLabel>
                <Select
                  name="standard"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  error={
                    formik2.touched.standard && Boolean(formik2.errors.standard)
                  }
                  value={formik2.values.standard}
                  label="Standard"
                  defaultValue="choose"
                  onChange={formik2.handleChange}
                >
                  <MenuItem disabled value="choose">
                    Select Standard
                  </MenuItem>
                  {standardData?.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.standard}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {formik2.touched.standard && formik2.errors.standard}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-md-6 col-lg-3">
              <FormControl fullWidth={true} className="mb-4 me-2">
                <InputLabel id="demo-simple-select-label">Division</InputLabel>
                <Select
                  name="division"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  error={
                    formik2.touched.division && Boolean(formik2.errors.division)
                  }
                  value={formik2.values.division}
                  label="Division"
                  defaultValue="choose"
                  onChange={formik2.handleChange}
                >
                  <MenuItem disabled value="choose">
                    Select Standard
                  </MenuItem>
                  {divisionFilteredData?.length > 0 &&
                    divisionFilteredData?.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.division}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {formik2.touched.division && formik2.errors.division}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-md-6 col-lg-3">
              <FormControl fullWidth={true} className="mb-4 me-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Attendance Date"
                    id="attendanceDate"
                    name="attendanceDate"
                    inputFormat="DD/MM/YYYY"
                    maxDate={new Date()}
                    shouldDisableDate={disableSunday}
                    value={formik2.values.attendanceDate}
                    onChange={(value) => {
                      formik2.setFieldValue("attendanceDate", value, true);
                      // formik2.setFieldValue("attendanceDate", Date.parse(value));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText style={{ color: "red" }}>
                  {formik2.touched.attendanceDate &&
                    formik2.errors.attendanceDate}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-md-6 col-lg-3">
              <Button
                type="button"
                size={"small"}
                variant="contained"
                color="success"
                onClick={formik2.handleSubmit}
                fullWidth
                style={{ height: "55px", marginBottom: "15px" }}
                startIcon={<i className="bi bi-binoculars"></i>}
                // style={{ marginBottom: "15px" }}
              >
                View Attendance
              </Button>
            </div>
          </div>
        </form>
        <form onSubmit={formik3.handleSubmit} className="mt-2 mb-5">
          <div className="row">
            <div className="col-md-6 col-lg-2">
              <FormControl fullWidth={true} className="mb-4 ">
                <InputLabel id="demo-simple-select-label">Standard</InputLabel>
                <Select
                  name="standard"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  error={
                    formik3.touched.standard && Boolean(formik3.errors.standard)
                  }
                  value={formik3.values.standard}
                  label="Standard"
                  defaultValue="choose"
                  onChange={formik3.handleChange}
                >
                  <MenuItem disabled value="choose">
                    Select Standard
                  </MenuItem>
                  {standardData?.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.standard}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {formik3.touched.standard && formik3.errors.standard}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-md-6 col-lg-2">
              <FormControl fullWidth={true} className="mb-4">
                <InputLabel id="demo-simple-select-label">Division</InputLabel>
                <Select
                  name="division"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  error={
                    formik3.touched.division && Boolean(formik3.errors.division)
                  }
                  value={formik3.values.division}
                  label="Division"
                  defaultValue="choose"
                  onChange={formik3.handleChange}
                >
                  <MenuItem disabled value="choose">
                    Select Standard
                  </MenuItem>
                  {divisionFilteredData?.length > 0 &&
                    divisionFilteredData?.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.division}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {formik3.touched.division && formik3.errors.division}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-md-6 col-lg-3">
              <FormControl fullWidth={true} className="mb-4 ">
                <InputLabel id="demo-simple-select-label">Student</InputLabel>
                <Select
                  name="student"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  error={
                    formik3.touched.student && Boolean(formik3.errors.student)
                  }
                  value={formik3.values.student}
                  label="Student"
                  defaultValue="choose"
                  onChange={formik3.handleChange}
                >
                  <MenuItem disabled value="choose">
                    Select Student
                  </MenuItem>
                  {studentsData?.length > 0 &&
                    studentsData?.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {`${option.firstName} ${option.middleName} ${option.lastName}`}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {formik3.touched.student && formik3.errors.student}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-md-6 col-lg-2">
              <FormControl fullWidth={true} className="mb-4">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    label="Attendance Date"
                    id="attendanceDate"
                    name="attendanceDate"
                    inputFormat="DD/MM/YYYY"
                    maxDate={new Date()}
                    shouldDisableDate={disableSunday}
                    value={formik3.values.attendanceDate}
                    onChange={(value) => {
                      formik3.setFieldValue("attendanceDate", value, true);
                      // formik3.setFieldValue("attendanceDate", Date.parse(value));
                    }}
                    renderInput={(params) => (
                      <TextField {...params} fullWidth />
                    )}
                  />
                </LocalizationProvider>
                <FormHelperText style={{ color: "red" }}>
                  {formik3.touched.attendanceDate &&
                    formik3.errors.attendanceDate}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-md-12 col-lg-3">
              <Button
                type="button"
                size={"small"}
                variant="contained"
                color="error"
                onClick={formik3.handleSubmit}
                fullWidth
                style={{ height: "55px" }}
                startIcon={<i className="bi bi-flag"></i>}
                // style={{ marginBottom: "15px" }}
              >
                Student Attendance By Month
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AttendanceComponent;
