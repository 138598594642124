import { useRegisterUserMutation } from "../../services/authApi";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetAllDesignationQuery } from "../../services/designationAPI";
import { toast } from "react-toastify";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Button,
  FormHelperText,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFormik } from "formik";
import * as yup from "yup";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const validationSchema = yup.object({
  firstname: yup.string().required("Please enter your first name"),
  lastname: yup.string().required("Please enter your last name"),
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("Please enter your email"),
  password: yup
    .string()
    .matches(
      /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{6,}$/,
      "Password must contain at least one uppercase letter, one special character, and one number"
    )
    .required("Password is required"),
  designation: yup.string().required("Please select a designation"),
  status: yup.boolean(),
});

const AddUserComponent = () => {
  const navigate = useNavigate();
  const [designation, setDesignation] = useState();
  // eslint-disable-next-line
  const [userData, setUserData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    designation: designation,
    isActive: false,
  });
  const { data: designationData, isSuccess: designationIsSuccess } =
    useGetAllDesignationQuery();
  useEffect(() => {
    function fetchData() {
      if (designationIsSuccess && designationData) {
        setDesignation(designationData.designation);
      }
    }

    fetchData();
  }, [designationIsSuccess, designationData]);
  let Content;
  const [addUser] = useRegisterUserMutation();
  const formik = useFormik({
    initialValues: userData,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));
      addUser(values)
        .unwrap()
        .then((payload) => {
          toast.success(`User added successfully`);
          navigate("/users");
        })
        .catch((error) =>
          toast.error(`Error adding User : ${error.data.message}`)
        );
    },
  });

  if (designationIsSuccess && !designationData) {
    Content = (
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-2">
            <div className="d-flex text-danger justify-content-center ">
              <i className="bi bi-people-fill" style={{ fontSize: 50 }}></i>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ marginLeft: "-12px" }}
        >
          <h1 className="form-title">Add User</h1>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-3">
          <h3>No Designation found please add Designation</h3>
        </div>
      </div>
    );
  } else {
    Content = (
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-2">
            <div className="d-flex text-danger justify-content-center ">
              <i className="bi bi-people-fill" style={{ fontSize: 50 }}></i>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ marginLeft: "-12px" }}
        >
          <h1 className="form-title">Add User</h1>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-3">
          <form onSubmit={formik.handleSubmit}>
            <TextField
              name="firstname"
              type="text"
              onChange={formik.handleChange}
              label="First Name"
              error={
                formik.touched.firstname && Boolean(formik.errors.firstname)
              }
              helperText={formik.touched.firstname && formik.errors.firstname}
              value={formik.values.firstname}
              placeholder="Enter First Name"
              onFocus={formik.handleBlur}
              className="mb-4"
              fullWidth
              variant={"outlined"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmailIcon color={"success"} />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              name="lastname"
              type="text"
              onChange={formik.handleChange}
              label="Last Name"
              error={formik.touched.lastname && Boolean(formik.errors.lastname)}
              helperText={formik.touched.lastname && formik.errors.lastname}
              value={formik.values.lastname}
              placeholder="Enter Last Name"
              onFocus={formik.handleBlur}
              className="mb-4"
              fullWidth
              variant={"outlined"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmailIcon color={"success"} />
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              name="email"
              type="text"
              onChange={formik.handleChange}
              label="Email"
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              value={formik.values.email}
              placeholder="Enter Email"
              onFocus={formik.handleBlur}
              className="mb-4"
              fullWidth
              variant={"outlined"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmailIcon color={"success"} />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              name="password"
              type="text"
              onChange={formik.handleChange}
              label="Password"
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              value={formik.values.password}
              placeholder="Enter Password"
              onFocus={formik.handleBlur}
              className="mb-4"
              fullWidth
              variant={"outlined"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmailIcon color={"success"} />
                  </InputAdornment>
                ),
              }}
            />
            <FormControl fullWidth={true} className="mb-3">
              <InputLabel id="demo-simple-select-label" shrink={true}>
                Designation
              </InputLabel>
              <Select
                name="designation"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                error={
                  formik.touched.designation &&
                  Boolean(formik.errors.designation)
                }
                value={formik.values.designation}
                label="Designation"
                defaultValue="choose"
                onChange={formik.handleChange}
              >
                <MenuItem disabled value="choose">
                  Select Standard
                </MenuItem>
                {designationData?.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.designation}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText style={{ color: "red" }}>
                {formik.touched.designation && formik.errors.designation}
              </FormHelperText>
            </FormControl>

            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.isActive}
                    onChange={formik.handleChange}
                    name="isActive"
                  />
                }
                label={formik.values.isActive ? "Active" : "Disabled"}
              />
            </FormGroup>

            <Button
              type="submit"
              size={"large"}
              variant="contained"
              // color="warning"

              fullWidth
              startIcon={<SaveIcon />}
              style={{ marginBottom: "15px", backgroundColor: "#522853" }}
            >
              Save
            </Button>
            <Button
              type="button"
              size={"large"}
              variant="contained"
              color="warning"
              onClick={() => navigate(-1)}
              fullWidth
              startIcon={<CancelIcon />}
              style={{ marginBottom: "15px" }}
            >
              Cancel
            </Button>
          </form>
        </div>
      </div>
    );
  }

  return Content;
};
export default AddUserComponent;
