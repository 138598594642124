import { useNavigate } from "react-router-dom";
import { useAddStandardMutation } from "../../services/standardAPI";
import { toast } from "react-toastify";
import { Button, InputAdornment, TextField } from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  standard: yup.string("Enter Standard").required("Standard is required"),
});

const AddStandardComponent = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      standard: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));
      addStandard(values)
        .unwrap()
        .then((payload) => {
          toast.success(`Standard added successfully`);
          navigate("/standard");
        })
        .catch((error) =>
          toast.error(`Error adding Standard : ${error.data.message}`)
        );
    },
  });
  const [addStandard] = useAddStandardMutation();

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-5">
            <div className="d-flex text-danger justify-content-center ">
              <i className="bi bi-postcard-fill" style={{ fontSize: 50 }}></i>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ marginLeft: "-12px" }}
        >
          <h1 className="form-title">Add Standard</h1>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-3">
          <form onSubmit={formik.handleSubmit}>
            <TextField
              id={"standard"}
              name="standard"
              type="text"
              onChange={formik.handleChange}
              className="mb-4"
              label="Enter Standard"
              fullWidth
              error={formik.touched.standard && Boolean(formik.errors.standard)}
              helperText={formik.touched.standard && formik.errors.standard}
              value={formik.values.standard}
              variant={"outlined"}
              InputProps={{
                // classes: { input: classes.input1 },
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmailIcon color={"success"} />
                  </InputAdornment>
                ),
              }}
            />

            <Button
              type="submit"
              size={"large"}
              variant="contained"
              // color="warning"

              fullWidth
              startIcon={<SaveIcon />}
              style={{ marginBottom: "15px", backgroundColor: "#522853" }}
            >
              Save
            </Button>
            <Button
              type="button"
              size={"large"}
              variant="contained"
              color="warning"
              onClick={() => navigate(-1)}
              fullWidth
              startIcon={<CancelIcon />}
              style={{ marginBottom: "15px" }}
            >
              Cancel
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddStandardComponent;
