import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAddStudentMutation } from "../../services/studentAPI";
import { useNavigate } from "react-router-dom";
import { Button, FormHelperText, InputLabel, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useGetAllStandardQuery } from "../../services/standardAPI";
import { useGetAllDivisionQuery } from "../../services/divisionAPI";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { disableSunday, formatDate } from "../../utils/sharedFunctions";
import Spinner from "../../shared/Spinner";

const validationSchema = yup.object({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  gender: yup.string().required("Gender is required"),
  standard: yup.string().required("Standard is required"),
  division: yup.string().required("Division is required"),
  admissionNo: yup.string().required("Admission number is required"),
  birthDate: yup.string().required("Birth date is required"),
  admissionDate: yup.string().required("Joining date is required"),
  address: yup.string().required("Address is required"),
  contactNo1: yup
    .string()
    .matches(
      /^(\+91[-\s]?)?[0]?(91)?[789]\d{9}$/,
      "Please enter a valid Indian mobile number"
    )
    .required("Contact number is required"),
  whatsAppNo: yup
    .string()
    .matches(
      /^(\+91[-\s]?)?[0]?(91)?[789]\d{9}$/,
      "Please enter a valid Indian mobile number"
    )
    .required("WhatsApp number is required"),
  emailId: yup.string().email("Invalid email"),
  emergencyContactName: yup
    .string()
    .required("Emergency contact name is required"),
  emergencyContactNo: yup
    .string()
    .matches(
      /^(\+91[-\s]?)?[0]?(91)?[789]\d{9}$/,
      "Please enter a valid Indian mobile number"
    )
    .required("Emergency contact number is required"),
  emergencyContactRelationship: yup
    .string()
    .required("Emergency contact relationship is required"),
  fatherName: yup.string().required("Father's name is required"),
  motherName: yup.string().required("Mother's name is required"),
});

const AddStudentComponent = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line
  const [studentData, setStudentData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    gender: "",
    standard: "",
    division: "",
    house_sports: "",
    admissionNo: "",
    admissionDate: "",
    leavingDate: "",
    aadhaarNo: 0,
    birthDate: "",
    bloodGroup: "",
    address: "",
    contactNo1: "",
    contactNo2: "",
    whatsAppNo: "",
    emailId: "",
    emergencyContactName: "",
    emergencyContactNo: "",
    emergencyContactRelationship: "",
    emergencyAddress: "",
    fatherName: "",
    fatherEduQualification: "",
    fatherDesignation: "",
    fatherOfficeAddress: "",
    fatherContactNo1: "",
    fatherContactNo2: "",
    fatherEmailId: "",
    fatherAadhaarNo: 0,
    motherName: "",
    motherEduQualification: "",
    motherDesignation: "",
    motherOfficeAddress: "",
    motherContactNo1: "",
    motherContactNo2: "",
    motherEmailId: "",
    motherAadhaarNo: 0,
    siblings: "",
  });

  const [standardData, setStandardData] = useState();
  const [divisionData, setDivisionData] = useState();
  const [divisionFilteredData, setDivisionFilteredData] = useState();
  const {
    data: StandardData,
    isSuccess: StandardIsSuccess,
    isLoading: stdLoading,
  } = useGetAllStandardQuery();
  const {
    data: DivisionData,
    isSuccess: DivisionIsSuccess,
    isLoading: divLoading,
  } = useGetAllDivisionQuery();

  const formik = useFormik({
    initialValues: studentData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.admissionDate = formatDate(values.admissionDate);
      values.birthDate = formatDate(values.birthDate);
      console.log(
        `Admission Date: ${values.birthDate} Birth Date: ${values.admissionDate}`
      );
      // console.log(JSON.stringify(values, null, 2));
      addStudent(values)
        .unwrap()
        .then((payload) => {
          toast.success(`Student added successfully`);
          navigate("/students");
        })
        .catch((error) =>
          toast.error(`Error adding Student : ${error.data.message}`)
        );
    },
  });

  useEffect(() => {
    function getStandard() {
      if (StandardIsSuccess && StandardData) {
        setStandardData(StandardData);
        // console.log(standardData);
      }
    }

    getStandard();
  }, [StandardIsSuccess, StandardData, standardData]);

  useEffect(() => {
    function getDivision() {
      if (DivisionIsSuccess && DivisionData) {
        // console.log(DivisionData);
        setDivisionData(DivisionData);

        let filteredData = getDivisionsForStandard(formik.values.standard);

        setDivisionFilteredData(filteredData);
        // console.log(filteredData);
      }
    }

    getDivision();
    // eslint-disable-next-line
  }, [DivisionIsSuccess, DivisionData, formik.values.standard]);

  const getDivisionsForStandard = (standard) => {
    const filteredDivisions = divisionData?.filter(
      (division) => division.standard._id === standard
    );
    return filteredDivisions;
  };

  const [addStudent] = useAddStudentMutation();

  if (stdLoading) {
    return <Spinner />;
  }
  if (divLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="container" style={{ marginBottom: "100px" }}>
        <div className="row">
          <div className="col-lg-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-5">
            <div className="d-flex text-danger justify-content-center ">
              <i
                className="bi bi-person-vcard-fill"
                style={{ fontSize: 50 }}
              ></i>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ marginLeft: "-12px" }}
        >
          <h1 className="form-title">Add Student</h1>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="row ">
            <div className="col-lg-3 col-md-6">
              <TextField
                name="firstName"
                type="text"
                onChange={formik.handleChange}
                label="First Name"
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                value={formik.values.firstName}
                placeholder="Enter First Name"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="middleName"
                type="text"
                onChange={formik.handleChange}
                label="Middle Name"
                error={
                  formik.touched.middleName && Boolean(formik.errors.middleName)
                }
                helperText={
                  formik.touched.middleName && formik.errors.middleName
                }
                value={formik.values.middleName}
                placeholder="Enter Middle Name"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="lastName"
                type="text"
                onChange={formik.handleChange}
                label="Last Name"
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName && formik.errors.lastName}
                value={formik.values.lastName}
                placeholder="Enter Last Name"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <FormControl fullWidth={true} className="mb-4">
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  name="gender"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  error={formik.touched.gender && Boolean(formik.errors.gender)}
                  value={formik.values.gender}
                  label="Gender"
                  onChange={formik.handleChange}
                >
                  <MenuItem disabled value="choose">
                    Select Gender
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {formik.touched.gender && formik.errors.gender}
                </FormHelperText>
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <FormControl fullWidth={true} className="mb-4">
                <InputLabel id="demo-simple-select-label">Standard</InputLabel>
                <Select
                  name="standard"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  error={
                    formik.touched.standard && Boolean(formik.errors.standard)
                  }
                  value={formik.values.standard}
                  label="Standard"
                  defaultValue="choose"
                  onChange={formik.handleChange}
                >
                  <MenuItem disabled value="choose">
                    Select Standard
                  </MenuItem>
                  {standardData?.map((option) => (
                    <MenuItem key={option._id} value={option._id}>
                      {option.standard}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {formik.touched.standard && formik.errors.standard}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-lg-3 col-md-6">
              <FormControl fullWidth={true} className="mb-4">
                <InputLabel id="demo-simple-select-label">Division</InputLabel>
                <Select
                  name="division"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  error={
                    formik.touched.division && Boolean(formik.errors.division)
                  }
                  value={formik.values.division}
                  label="Division"
                  defaultValue="choose"
                  onChange={formik.handleChange}
                >
                  <MenuItem disabled value="choose">
                    Select Standard
                  </MenuItem>
                  {divisionFilteredData?.length > 0 &&
                    divisionFilteredData?.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {option.division}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {formik.touched.division && formik.errors.division}
                </FormHelperText>
              </FormControl>
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="house_sports"
                type="text"
                onChange={formik.handleChange}
                label="House Sports"
                error={
                  formik.touched.house_sports &&
                  Boolean(formik.errors.house_sports)
                }
                helperText={
                  formik.touched.house_sports && formik.errors.house_sports
                }
                value={formik.values.house_sports}
                placeholder="Enter House Sports"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>

            <div className="col-lg-3 col-md-6">
              <TextField
                name="admissionNo"
                type="text"
                onChange={formik.handleChange}
                label="Admission Number"
                error={
                  formik.touched.admissionNo &&
                  Boolean(formik.errors.admissionNo)
                }
                helperText={
                  formik.touched.admissionNo && formik.errors.admissionNo
                }
                value={formik.values.admissionNo}
                placeholder="Enter Admission Number"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <TextField
                    name="aadhaarNo"
                    type="number"
                    onChange={formik.handleChange}
                    label="Aadhaar Number"
                    error={
                      formik.touched.aadhaarNo &&
                      Boolean(formik.errors.aadhaarNo)
                    }
                    helperText={
                      formik.touched.aadhaarNo && formik.errors.aadhaarNo
                    }
                    value={formik.values.aadhaarNo}
                    placeholder="Enter Aadhaar Number"
                    onFocus={formik.handleBlur}
                    className="mb-4"
                    fullWidth
                    variant={"outlined"}
                  />
                </div>
                <div className="col-lg-4 col-md-6">
                  <FormControl fullWidth={true}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Birth Date"
                        id="birthDate"
                        name="birthDate"
                        inputFormat="DD/MM/YYYY"
                        value={formik.values.birthDate}
                        onChange={(value) => {
                          formik.setFieldValue("birthDate", value, true);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                    <FormHelperText style={{ color: "red" }}>
                      {formik.touched.birthDate && formik.errors.birthDate}
                    </FormHelperText>
                  </FormControl>
                </div>
                <div className="col-lg-4 col-md-6">
                  <FormControl fullWidth={true}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Joining Date"
                        id="admissionDate"
                        name="admissionDate"
                        inputFormat="DD/MM/YYYY"
                        shouldDisableDate={disableSunday}
                        value={formik.values.admissionDate}
                        onChange={(value) => {
                          formik.setFieldValue("admissionDate", value, true);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth />
                        )}
                      />
                    </LocalizationProvider>
                    <FormHelperText style={{ color: "red" }}>
                      {formik.touched.admissionDate &&
                        formik.errors.admissionDate}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-g col-md-6">
                  <TextField
                    name="contactNo1"
                    type="text"
                    onChange={formik.handleChange}
                    label="Contact Number One"
                    error={
                      formik.touched.contactNo1 &&
                      Boolean(formik.errors.contactNo1)
                    }
                    helperText={
                      formik.touched.contactNo1 && formik.errors.contactNo1
                    }
                    value={formik.values.contactNo1}
                    placeholder="Enter Contact Number One"
                    onFocus={formik.handleBlur}
                    className="mb-4"
                    fullWidth
                    variant={"outlined"}
                  />
                </div>
                <div className="col-lg-g col-md-6">
                  <TextField
                    name="contactNo2"
                    type="text"
                    onChange={formik.handleChange}
                    label="Contact Number Two"
                    error={
                      formik.touched.contactNo2 &&
                      Boolean(formik.errors.contactNo2)
                    }
                    helperText={
                      formik.touched.contactNo2 && formik.errors.contactNo2
                    }
                    value={formik.values.contactNo2}
                    placeholder="Enter Contact Number Two"
                    onFocus={formik.handleBlur}
                    className="mb-4"
                    fullWidth
                    variant={"outlined"}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6">
              <TextField
                name="address"
                type="text"
                onChange={formik.handleChange}
                label="Address"
                multiline
                rows={4}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
                value={formik.values.address}
                placeholder="Enter Address"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <TextField
                name="whatsAppNo"
                type="text"
                onChange={formik.handleChange}
                label="WhatsApp Number"
                error={
                  formik.touched.whatsAppNo && Boolean(formik.errors.whatsAppNo)
                }
                helperText={
                  formik.touched.whatsAppNo && formik.errors.whatsAppNo
                }
                value={formik.values.whatsAppNo}
                placeholder="Enter WhatsApp Number"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="emailId"
                type="text"
                onChange={formik.handleChange}
                label="Email ID"
                error={formik.touched.emailId && Boolean(formik.errors.emailId)}
                helperText={formik.touched.emailId && formik.errors.emailId}
                value={formik.values.emailId}
                placeholder="Enter Email ID"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="emergencyContactName"
                type="text"
                onChange={formik.handleChange}
                label="Emergency Contact Name"
                error={
                  formik.touched.emergencyContactName &&
                  Boolean(formik.errors.emergencyContactName)
                }
                helperText={
                  formik.touched.emergencyContactName &&
                  formik.errors.emergencyContactName
                }
                value={formik.values.emergencyContactName}
                placeholder="Enter Emergency Contact Name"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="emergencyContactNo"
                type="text"
                onChange={formik.handleChange}
                label="Emergency Contact Number"
                error={
                  formik.touched.emergencyContactNo &&
                  Boolean(formik.errors.emergencyContactNo)
                }
                helperText={
                  formik.touched.emergencyContactNo &&
                  formik.errors.emergencyContactNo
                }
                value={formik.values.emergencyContactNo}
                placeholder="Enter Emergency Contact Number"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <TextField
                name="emergencyContactRelationship"
                type="text"
                onChange={formik.handleChange}
                label="Emergency Contact Relation"
                error={
                  formik.touched.emergencyContactRelationship &&
                  Boolean(formik.errors.emergencyContactRelationship)
                }
                helperText={
                  formik.touched.emergencyContactRelationship &&
                  formik.errors.emergencyContactRelationship
                }
                value={formik.values.emergencyContactRelationship}
                placeholder="Enter Emergency Contact Relation"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="emergencyAddress"
                type="text"
                onChange={formik.handleChange}
                label="Emergency Address"
                error={
                  formik.touched.emergencyAddress &&
                  Boolean(formik.errors.emergencyAddress)
                }
                helperText={
                  formik.touched.emergencyAddress &&
                  formik.errors.emergencyAddress
                }
                value={formik.values.emergencyAddress}
                placeholder="Enter Emergency Address"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="fatherName"
                type="text"
                onChange={formik.handleChange}
                label="Father Name"
                error={
                  formik.touched.fatherName && Boolean(formik.errors.fatherName)
                }
                helperText={
                  formik.touched.fatherName && formik.errors.fatherName
                }
                value={formik.values.fatherName}
                placeholder="Enter Father Name"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="fatherEduQualification"
                type="text"
                onChange={formik.handleChange}
                label="Father Educational Qualification"
                error={
                  formik.touched.fatherEduQualification &&
                  Boolean(formik.errors.fatherEduQualification)
                }
                helperText={
                  formik.touched.fatherEduQualification &&
                  formik.errors.fatherEduQualification
                }
                value={formik.values.fatherEduQualification}
                placeholder="Enter Father Educational Qualification"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <TextField
                name="fatherDesignation"
                type="text"
                onChange={formik.handleChange}
                label="Father Designation"
                error={
                  formik.touched.fatherDesignation &&
                  Boolean(formik.errors.fatherDesignation)
                }
                helperText={
                  formik.touched.fatherDesignation &&
                  formik.errors.fatherDesignation
                }
                value={formik.values.fatherDesignation}
                placeholder="Enter Father Designation"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="fatherOfficeAddress"
                type="text"
                onChange={formik.handleChange}
                label="Father's Office Address"
                error={
                  formik.touched.fatherOfficeAddress &&
                  Boolean(formik.errors.fatherOfficeAddress)
                }
                helperText={
                  formik.touched.fatherOfficeAddress &&
                  formik.errors.fatherOfficeAddress
                }
                value={formik.values.fatherOfficeAddress}
                placeholder="Enter Father's Office Address"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="fatherContactNo1"
                type="text"
                onChange={formik.handleChange}
                label="Father's Contact Number One"
                error={
                  formik.touched.fatherContactNo1 &&
                  Boolean(formik.errors.fatherContactNo1)
                }
                helperText={
                  formik.touched.fatherContactNo1 &&
                  formik.errors.fatherContactNo1
                }
                value={formik.values.fatherContactNo1}
                placeholder="Enter Father's Contact Number One"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="fatherContactNo2"
                type="text"
                onChange={formik.handleChange}
                label="Father's Contact Number Two"
                error={
                  formik.touched.fatherContactNo2 &&
                  Boolean(formik.errors.fatherContactNo2)
                }
                helperText={
                  formik.touched.fatherContactNo2 &&
                  formik.errors.fatherContactNo2
                }
                value={formik.values.fatherContactNo2}
                placeholder="Enter Father's Contact Number Two"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <TextField
                name="fatherEmailId"
                type="text"
                onChange={formik.handleChange}
                label="Father's Email ID"
                error={
                  formik.touched.fatherEmailId &&
                  Boolean(formik.errors.fatherEmailId)
                }
                helperText={
                  formik.touched.fatherEmailId && formik.errors.fatherEmailId
                }
                value={formik.values.fatherEmailId}
                placeholder="Enter Father's Email ID"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="fatherAadhaarNo"
                type="number"
                onChange={formik.handleChange}
                label="Father's Aadhaar Number"
                error={
                  formik.touched.fatherAadhaarNo &&
                  Boolean(formik.errors.fatherAadhaarNo)
                }
                helperText={
                  formik.touched.fatherAadhaarNo &&
                  formik.errors.fatherAadhaarNo
                }
                value={formik.values.fatherAadhaarNo}
                placeholder="Enter Father's Aadhaar Number"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="motherName"
                type="text"
                onChange={formik.handleChange}
                label="Mother's Name"
                error={
                  formik.touched.motherName && Boolean(formik.errors.motherName)
                }
                helperText={
                  formik.touched.motherName && formik.errors.motherName
                }
                value={formik.values.motherName}
                placeholder="Enter Mother's Name"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="motherEduQualification"
                type="text"
                onChange={formik.handleChange}
                label="Mother's Educational Qualification"
                error={
                  formik.touched.motherEduQualification &&
                  Boolean(formik.errors.motherEduQualification)
                }
                helperText={
                  formik.touched.motherEduQualification &&
                  formik.errors.motherEduQualification
                }
                value={formik.values.motherEduQualification}
                placeholder="Enter Mother's Educational Qualification"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <TextField
                name="motherDesignation"
                type="text"
                onChange={formik.handleChange}
                label="Mother's Designation"
                error={
                  formik.touched.motherDesignation &&
                  Boolean(formik.errors.motherDesignation)
                }
                helperText={
                  formik.touched.motherDesignation &&
                  formik.errors.motherDesignation
                }
                value={formik.values.motherDesignation}
                placeholder="Enter Mother's Designation"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="motherOfficeAddress"
                type="text"
                onChange={formik.handleChange}
                label="Mother's Office Address"
                error={
                  formik.touched.motherOfficeAddress &&
                  Boolean(formik.errors.motherOfficeAddress)
                }
                helperText={
                  formik.touched.motherOfficeAddress &&
                  formik.errors.motherOfficeAddress
                }
                value={formik.values.motherOfficeAddress}
                placeholder="Enter Mother's Office Address"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="motherContactNo1"
                type="text"
                onChange={formik.handleChange}
                label="Mother's Contact Number One"
                error={
                  formik.touched.motherContactNo1 &&
                  Boolean(formik.errors.motherContactNo1)
                }
                helperText={
                  formik.touched.motherContactNo1 &&
                  formik.errors.motherContactNo1
                }
                value={formik.values.motherContactNo1}
                placeholder="Enter Mother's Contact Number One"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="motherContactNo2"
                type="text"
                onChange={formik.handleChange}
                label="Mother's Contact Number Two"
                error={
                  formik.touched.motherContactNo2 &&
                  Boolean(formik.errors.motherContactNo2)
                }
                helperText={
                  formik.touched.motherContactNo2 &&
                  formik.errors.motherContactNo2
                }
                value={formik.values.motherContactNo2}
                placeholder="Enter Mother's Contact Number Two"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <TextField
                name="motherEmailId"
                type="text"
                onChange={formik.handleChange}
                label="Mother's Email ID"
                error={
                  formik.touched.motherEmailId &&
                  Boolean(formik.errors.motherEmailId)
                }
                helperText={
                  formik.touched.motherEmailId && formik.errors.motherEmailId
                }
                value={formik.values.motherEmailId}
                placeholder="Enter Mother's Email ID"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="motherEmailId"
                type="text"
                onChange={formik.handleChange}
                label="Mother's Email ID"
                error={
                  formik.touched.motherEmailId &&
                  Boolean(formik.errors.motherEmailId)
                }
                helperText={
                  formik.touched.motherEmailId && formik.errors.motherEmailId
                }
                value={formik.values.motherEmailId}
                placeholder="Enter Mother's Email ID"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="motherAadhaarNo"
                type="number"
                onChange={formik.handleChange}
                label="Mother's Aadhaar Number"
                error={
                  formik.touched.motherAadhaarNo &&
                  Boolean(formik.errors.motherAadhaarNo)
                }
                helperText={
                  formik.touched.motherAadhaarNo &&
                  formik.errors.motherAadhaarNo
                }
                value={formik.values.motherAadhaarNo}
                placeholder="Enter Mother's Aadhaar Number"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <TextField
                name="siblings"
                type="text"
                onChange={formik.handleChange}
                label="Siblings"
                error={
                  formik.touched.siblings && Boolean(formik.errors.siblings)
                }
                helperText={formik.touched.siblings && formik.errors.siblings}
                value={formik.values.siblings}
                placeholder="Enter Siblings"
                onFocus={formik.handleBlur}
                className="mb-4"
                fullWidth
                variant={"outlined"}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Button
                type="submit"
                size={"large"}
                variant="contained"
                color="secondary"
                fullWidth
                startIcon={<SaveIcon />}
                style={{ marginBottom: "15px" }}
              >
                Save
              </Button>
            </div>
            <div className="col-md-6">
              <Button
                type="button"
                size={"large"}
                variant="contained"
                color="warning"
                onClick={() => navigate(-1)}
                fullWidth
                startIcon={<CancelIcon />}
                style={{ marginBottom: "15px" }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};
export default AddStudentComponent;
