import {standardAPI} from "../services/standardAPI";
import {divisionAPI} from "./divisionAPI";
import {subjectAPI} from "./subjectAPI";
import {designationAPI} from "./designationAPI";
import {authApi} from "./authApi";
import {studentAPI} from "./studentAPI";
import {attendanceAPI} from "./attendanceAPI";
import {performanceAPI} from "./performanceAPI";
import {resultTypeAPI} from "./resultTypeAPI";
import {resultAPI} from "./resultAPI";

export const GetSingleStandardFromStore = (id, dispatch) => {
    return dispatch(standardAPI.endpoints.getSingleStandard.initiate(id));
};
export const GetSingleDivisionFromStore = (id, dispatch) => {
    return dispatch(divisionAPI.endpoints.getSingleDivision.initiate(id));
};
export const GetSingleSubjectFromStore = (id, dispatch) => {
    return dispatch(subjectAPI.endpoints.getSingleSubject.initiate(id));
};
export const GetSingleDesignationFromStore = (id, dispatch) => {
    return dispatch(designationAPI.endpoints.getSingleDesignation.initiate(id));
};
export const GetSingleUserFromStore = (id, dispatch) => {
    return dispatch(authApi.endpoints.getSingleUser.initiate(id));
};
export const GetSingleStudentFromStore = (id, dispatch) => {
    return dispatch(studentAPI.endpoints.getSingleStudent.initiate(id));
};
export const GetAllStudentByStandardDivision = (std, div, dispatch) => {
    return dispatch(
        studentAPI.endpoints.getAllStudentsByStandardDivision.initiate({std, div})
    );
};
export const GetAttendancesPerformanceByMonthStandardDivisionStudent = (
    performanceDate,
    standard,
    division,
    student,
    dispatch
) => {
    return dispatch(
        attendanceAPI.endpoints.getAttendancesPerformanceByMonthStandardDivisionStudent.initiate(
            {
                performanceDate,
                standard,
                division,
                student,
            }
        )
    );
};
export const GetPerformance = (
    performanceDate,
    standard,
    division,
    student,
    dispatch
) => {
    // console.log("data",performanceDate,standard,division,student)
    return dispatch(
        performanceAPI.endpoints.getPerformance.initiate({
            performanceDate,
            standard,
            division,
            student,
        })
    );
};
export const GetPerformanceByClass = (
    performanceDate,
    standard,
    division,
    dispatch
) => {
    // console.log("data",performanceDate,standard,division,student)
    return dispatch(
        performanceAPI.endpoints.getPerformanceByClass.initiate({
            performanceDate,
            standard,
            division,
        })
    );
};

export const GetSingleResultTypeFromStore = (id, dispatch) => {
    return dispatch(resultTypeAPI.endpoints.getSingleResultType.initiate(id));
};
export const GetResultDetailsFromStore = (id, dispatch) => {
    const cacheBuster = Date.now(); // Generate a unique value for cache-busting

    return dispatch(
        resultAPI.endpoints.getAllResultDetailsByResultMasterId.initiate(
            id,
            {
                // Append the cacheBuster to the request URL
                forceRefetch: true,
                url: `/resultMaster/result/${id}?_cacheBuster=${cacheBuster}`,
            }
        )
    );
};
// export const GetResultDetailsFromStore = (id, dispatch) => {
//   return dispatch(resultAPI.endpoints.getAllResultDetailsByResultMasterId.initiate(id));
// };

export const GetAllResultDetailByStudentResultMaster = (
    studentID, resultMasterID, dispatch
) => {
    // console.log("data",studentID,resultMasterID)
    return dispatch(
        resultAPI.endpoints.getAllResultDetailByStudentResultMasterId.initiate({
            studentID, resultMasterID
        })
    );
};

