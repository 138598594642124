import React, { useState } from "react";
import HeaderComponent from "../../shared/header.component";
import { useNavigate } from "react-router-dom";
import {
  useDeleteResultTypeMutation,
  useGetAllResultTypeQuery,
} from "../../services/resultTypeAPI";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import createCache from "@emotion/cache";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Visibility } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Spinner from "../../shared/Spinner";
import { toast } from "react-toastify";
import { CacheProvider } from "@emotion/react";
import MUIDataTable from "mui-datatables";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { flattenObject } from "../../utils/sharedFunctions";
import moment from "moment/moment";
import useAdminAuth from "../../hooks/useAdminAuth";

const ResultTypeComponent = () => {
  const authState = useAdminAuth("Result Type");
  const navigate = useNavigate();

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const { data, isLoading, isError, error, isSuccess } =
    useGetAllResultTypeQuery();
  const [deleteResultType] = useDeleteResultTypeMutation();
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              padding: "25px",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              // backgroundColor: "#FF0000",
            },
          },
        },
      },
    });

  const muiCache = createCache({
    key: "mui-datatables",
    prepend: true,
  });

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "result_type",
      label: "Result Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "user.email",
      label: "Added By",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "addDate",
      label: "Date Added",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "editDate",
      label: "Date Updated",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title="View Result Type">
                <IconButton
                  onClick={() =>
                    navigate("/result-type/view/" + tableMeta.rowData[0])
                  }
                >
                  <Visibility color={"primary"} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit Result Type">
                <IconButton
                  onClick={() =>
                    navigate("/result-type/edit/" + tableMeta.rowData[0])
                  }
                >
                  <EditIcon color={"warning"} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete Result Type">
                <IconButton
                  onClick={() => {
                    setConfirmDialog({
                      isOpen: true,
                      title: "Are you sure to delete this record?",
                      subTitle: "You can't undo this operation",
                      onConfirm: () => {
                        // console.log("Deleted");
                        deleteResultTypeFromDB(
                          tableMeta.rowData[0],
                          tableMeta.rowData[1]
                        );
                      },
                    });
                  }}
                >
                  <DeleteForeverIcon color={"error"} />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const HeaderElements = () => (
    <>
      <Tooltip title="Add Result Type">
        <IconButton onClick={() => navigate("/result-type/add")}>
          <AddCircleIcon
            color={"warning"}
            sx={{ fontSize: { xs: 26, sm: 32 } }}
          />
        </IconButton>
      </Tooltip>
    </>
  );

  const options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "vertical",
    // tableBodyHeight,
    // tableBodyMaxHeight,
    onTableChange: (action, state) => {
      // console.log(action);
      // console.dir(state);
    },
    rowsPerPage: 5,
    resizableColumns: false,
    rowsPerPageOptions: [5, 10, 15, 25, 50, 100, 150],
    jumpToPage: true,
    showLastButton: true,
    elevation: 0,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total Per Page",
        displayRows: "of",
        jumpToPage: "Page",
      },
      body: {
        noMatch: "No Result Type to display please Add Result Type",
      },
    },
    selectableRows: "none",
    customToolbar: () => <HeaderElements />,
  };
  let Content;
  if (isLoading) {
    return <Spinner />;
  }

  if (authState.designation === "Admin" && isError && error) {
    Content = (
      <div className="container">
        <div className="row">
          <HeaderComponent
            title="Result Type"
            icon="bi bi-clipboard-data-fill"
          />
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-3">
            <h1 className="form-title">No Data</h1>
          </div>
        </div>
      </div>
    );
  }
  if (authState.designation === "Admin" && isSuccess && data) {
    let arrayValues = data.map((_data) => {
      return flattenObject(_data);
    });
    // console.log(arrayValues);
    let TableData = arrayValues.map((row) => {
      const updatedRow = {
        ...row,
        addDate: moment(row.createdAt).format("MMMM Do YYYY"),
        editDate: moment(row.updatedAt).format("MMMM Do YYYY"),
      };
      return updatedRow;
    });
    Content = (
      <div className="container">
        <div className="row">
          <HeaderComponent
            title="Result Type"
            icon="bi bi-clipboard-data-fill"
          />
          <div className="mb-5  mui-table">
            {/*{JSON.stringify(standard)}*/}
            <CacheProvider value={muiCache}>
              <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  // title={""}
                  data={TableData}
                  columns={columns}
                  options={options}
                />
              </ThemeProvider>
            </CacheProvider>

            <Dialog open={confirmDialog.isOpen}>
              <DialogContent>
                <Typography variant="h6">{confirmDialog.title}</Typography>
                <Typography variant="subtitle2" color={"indigo"}>
                  {confirmDialog.subTitle}
                </Typography>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center", marginBottom: 2 }}>
                <Button
                  variant={"contained"}
                  color={"error"}
                  onClick={confirmDialog.onConfirm}
                  size={"small"}
                >
                  Yes
                </Button>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  size={"small"}
                  onClick={() =>
                    setConfirmDialog({ ...confirmDialog, isOpen: false })
                  }
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
  const deleteResultTypeFromDB = async (id, result_type) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    await deleteResultType(id)
      .unwrap()
      .then((payload) => {
        toast.success(
          `Successfully deleted Result type ${result_type}`
          // `Successfully deleted Standard ${JSON.stringify(payload)}`
        );
        navigate("/result-type");
      })
      .catch((error) =>
        toast.error(
          `Error deleting Result type ${result_type} ${error.data.message}`
        )
      );
  };
  return <>{Content}</>;
};

export default ResultTypeComponent;
