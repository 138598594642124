import "./auth.css";
import { useState } from "react";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../app/authSlice";
import { useLoginUserMutation } from "../../services/authApi";
import { toast } from "react-toastify";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import Spinner from "../../shared/Spinner";

const LoginComponent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);
  if (user.token) {
    navigate("/dashboard");
  }
  const [loginUser, { data, isLoading, isError, error, isSuccess }] =
      useLoginUserMutation();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const { email, password, showPassword } = formData;

  const onSubmit = async (e) => {
    e.preventDefault();
    // console.log(formData);
    await loginUser({ email, password });
  };
  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClickShowPassword = () => {
    setFormData({
      ...formData,
      showPassword: !formData.showPassword,
    });
  };

  const handleMouseDownPassword = (e) => {
    e.preventDefault();
  };

  if (isLoading) {
    return <Spinner />;
  }

  if (isSuccess && data) {
    localStorage.setItem("token", data.token);
    // console.log(data)
    let user = {
      _id: data._id,
      firstname: data.firstname,
      lastname: data.lastname,
      email: data.email,
      designation: data.designation,
      fullname: data.firstname + " " + data.lastname,
      token: data.token,
    };
    dispatch(setUser(user));
    navigate("/dashboard");

    toast.success("Successfully logged in !");
  }
  if (isError && error) {
    const errMessage = error.data?.message;
    if (errMessage === undefined) {
      console.log("Something went wrong...");
      toast.error("Something went wrong...");
    }
    toast.error(errMessage);
  }

  return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-5">
              <div className="d-flex text-danger justify-content-center ">
                <i
                    className="bi bi-person-check-fill"
                    style={{ fontSize: 50 }}
                ></i>
              </div>
            </div>
          </div>
          <div
              className="d-flex justify-content-center"
              style={{ marginLeft: "-12px" }}
          >
            <h1 className="form-title">Login</h1>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-3">
            <form onSubmit={onSubmit}>
              <TextField
                  name="email"
                  type="email"
                  onChange={onChange}
                  className="mb-4"
                  label="Email"
                  value={email}
                  placeholder="Enter email"
                  fullWidth
                  required
                  variant={"outlined"}
                  InputProps={{
                    // classes: { input: classes.input1 },
                    endAdornment: (
                        <InputAdornment position="end">
                          <AlternateEmailIcon color={"success"} />
                        </InputAdornment>
                    ),
                  }}
              />
              <TextField
                  name="password"
                  type={showPassword ? "text" : "password"}
                  onChange={onChange}
                  className="mb-4"
                  label="Password"
                  value={password}
                  placeholder="Enter Password"
                  fullWidth
                  required
                  variant={"outlined"}
                  InputProps={{
                    // classes: { input: classes.input1 },
                    endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                          >
                            {showPassword ? (
                                <VisibilityOff />
                            ) : (
                                <Visibility color={"error"} />
                            )}
                          </IconButton>
                        </InputAdornment>
                    ),
                  }}
              />
              <Button
                  type="submit"
                  size={"large"}
                  variant="contained"
                  // color="warning"

                  fullWidth
                  startIcon={<LockOpenIcon />}
                  style={{ marginBottom: "15px", backgroundColor: "#522853" }}
              >
                Login
              </Button>
            </form>
          </div>
        </div>
      </>
  );
};

export default LoginComponent;
