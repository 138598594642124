import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useUpdateDivisionMutation } from "../../services/divisionAPI";
import { GetSingleDivisionFromStore } from "../../services/storeAccessAPI";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import FormControl from "@mui/material/FormControl";
import {
  Button,
  FormHelperText,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useGetAllStandardQuery } from "../../services/standardAPI";

const validationSchema = yup.object({
  standard: yup.string("Enter Standard").required("Standard is required"),
  division: yup.string("Enter Division").required("Division is required"),
});

const EditDivisionComponent = () => {
  const params = useParams();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [standard, setStandard] = useState();
  const userData = useSelector((state) => state.auth);
  const [divisionData, setDivisionData] = useState({
    standard: "",
    division: "",
  });
  const { data: standardData, isSuccess: standardIsSuccess } =
    useGetAllStandardQuery();
  const dispatch = useDispatch();
  const [editDivision] = useUpdateDivisionMutation();

  useEffect(() => {
    function fetchData() {
      if (standardIsSuccess && standardData) {
        setStandard(standardData.standard);
      }
    }

    fetchData();
  }, [standardIsSuccess, standardData]);

  useEffect(() => {
    GetSingleDivisionFromStore(params.id, dispatch)
      .then((data) => {
        setDivisionData({
          ...divisionData,
          _id: data.data._id,
          standard: data.data.standard._id,
          division: data.data.division,
          user: userData._id,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, [dispatch, params.id]);

  const formik = useFormik({
    initialValues: divisionData,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
      editDivision(values)
        .unwrap()
        .then((payload) => {
          toast.success(`Division added successfully`);
          navigate("/division");
        })
        .catch((error) =>
          toast.error(`Error adding Division : ${error.data.message}`)
        );
    },
  });

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-2">
            <div className="d-flex text-danger justify-content-center ">
              <i className="bi bi-bar-chart-steps" style={{ fontSize: 50 }}></i>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ marginLeft: "-12px" }}
        >
          <h1 className="form-title">Edit Division</h1>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-3">
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth={true} className="mb-3">
              <InputLabel id="demo-simple-select-label" shrink={true}>
                Standard
              </InputLabel>
              <Select
                name="standard"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                error={
                  formik.touched.standard && Boolean(formik.errors.standard)
                }
                value={formik.values.standard}
                label="Standard"
                defaultValue="choose"
                onChange={formik.handleChange}
              >
                {/*<MenuItem disabled value="choose">*/}
                {/*  Select Standard*/}
                {/*</MenuItem>*/}
                {standardData?.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.standard}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText style={{ color: "red" }}>
                {formik.touched.standard && formik.errors.standard}
              </FormHelperText>
            </FormControl>

            <TextField
              name="division"
              type="text"
              onChange={formik.handleChange}
              label="Division"
              error={formik.touched.division && Boolean(formik.errors.division)}
              helperText={formik.touched.division && formik.errors.division}
              value={formik.values.division}
              placeholder="Enter Division"
              onFocus={formik.handleBlur}
              className="mb-4"
              fullWidth
              variant={"outlined"}
              InputProps={{
                // classes: { input: classes.input1 },
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmailIcon color={"success"} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              size={"large"}
              variant="contained"
              // color="warning"

              fullWidth
              startIcon={<SaveIcon />}
              style={{ marginBottom: "15px", backgroundColor: "#522853" }}
            >
              Save
            </Button>
            <Button
              type="button"
              size={"large"}
              variant="contained"
              color="warning"
              onClick={() => navigate(-1)}
              fullWidth
              startIcon={<CancelIcon />}
              style={{ marginBottom: "15px" }}
            >
              Cancel
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};
export default EditDivisionComponent;
