import {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from 'react-router-dom';
import {useFormik} from "formik";
import * as yup from "yup";
import HeaderComponent from "../../shared/header.component";
import Spinner from "../../shared/Spinner";
import {GetAllResultDetailByStudentResultMaster} from "../../services/storeAccessAPI";
import {useDispatch} from "react-redux";
import {Button, TextField} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import {useUpdateResultDetailsMutation} from "../../services/resultAPI";
import {toast} from "react-toastify";
import CancelIcon from "@mui/icons-material/Cancel";

const EditResultComponent = () => {
    const [result, setResult] = useState([]);
    const params = useParams();
    const studentID = params.studentID;
    const resultMasterID = params.resultMasterID;
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [updateResultDetails] = useUpdateResultDetailsMutation()
    const navigate = useNavigate();

    const validationSchema = yup.object({
        written_marks: yup.number("Please enter a numeric value"),
        oral_marks: yup.number("Please enter a numeric value"),
        grace_marks: yup.number("Please enter a numeric value"),
    });

    const formik = useFormik({
        initialValues: result.reduce((values, resultDetail) => {
            values[`oral_marks_${resultDetail._id}`] =
                resultDetail.oral_marks !== undefined ? resultDetail.oral_marks : "";
            values[`grace_marks_${resultDetail._id}`] =
                resultDetail.grace_marks !== undefined ? resultDetail.grace_marks : "";
            values[`written_marks_${resultDetail._id}`] = resultDetail.written_marks || "";
            return values;
        }, {}),
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values, {setSubmitting}) => {
            let error = false;
            const modifiedData = result.map((resultDetail) => {
                const oralMarks = values[`oral_marks_${resultDetail._id}`] || 0;
                const graceMarks = values[`grace_marks_${resultDetail._id}`] || 0;
                const writtenMarks = values[`written_marks_${resultDetail._id}`] || 0;
                const totalMarks = resultDetail.total_marks || 0;

                const sumMarks = oralMarks + graceMarks + writtenMarks;

                if (sumMarks > totalMarks) {
                    alert(`Sum of marks exceeds total marks for subject "${resultDetail.subject.subject}"`);
                    error = true;
                    formik.setFieldError(`oral_marks_${resultDetail._id}`, "Sum of marks exceeds total marks");
                    formik.setFieldError(`grace_marks_${resultDetail._id}`, "Sum of marks exceeds total marks");
                    formik.setFieldError(`written_marks_${resultDetail._id}`, "Sum of marks exceeds total marks");

                }

                return {
                    _id: resultDetail._id,
                    user: resultDetail.user,
                    result_master: resultDetail.result_master,
                    student: resultDetail.student,
                    subject: resultDetail.subject._id,
                    total_marks: totalMarks,
                    written_marks: writtenMarks,
                    oral_marks: oralMarks,
                    grace_marks: graceMarks,
                    createdAt: resultDetail.createdAt,
                    updatedAt: resultDetail.updatedAt,
                    __v: resultDetail.__v,
                };
            });
            if (!error) {
                console.log(modifiedData)
                updateResultDetails(modifiedData)
                    .unwrap()
                    .then((payload) => {
                        toast.success(`Result updated successfully`);
                        navigate(-1);
                        // navigate("/resultMasterStaff");
                    })
                    .catch((error) =>
                        toast.error(`Error updating Result : ${error.data.message}`)
                    );
            }
        },


    });

    const getResultDetailsData = useCallback(() => {
        if (params.studentID && params.resultMasterID) {
            setLoading(true);
            GetAllResultDetailByStudentResultMaster(studentID, resultMasterID, dispatch)
                .then((data) => {
                    setResult(data.data);
                    setLoading(false);
                    setIsSuccess(true);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    setIsError(true);
                });
        }
        // eslint-disable-next-line
    }, [studentID, resultMasterID, dispatch]);

    useEffect(() => {
        getResultDetailsData();
    }, [getResultDetailsData]);

    let Content;
    if (loading) {
        return <Spinner/>;
    }
    if (isError) {
        Content = (
            <div className="container">
                <div className="row">
                    <HeaderComponent
                        title="Edit Result"
                        icon="bi bi-clipboard-data-fill"
                    />
                    <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-3">
                        <h5 className="text-danger text-center">Error Getting Data</h5>
                    </div>
                </div>
            </div>
        );
    }
    if (isSuccess) {
        Content = (
            <div className="container">
                <div className="row">
                    <HeaderComponent
                        title="Edit Result"
                        icon="bi bi-clipboard-data-fill"
                    />
                    <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-3">
                        <form onSubmit={formik.handleSubmit} style={{marginBottom: "20px"}}>
                            {result.map((resultDetail) => (
                                <div key={resultDetail._id}>
                                    <h4>Subject: {resultDetail.subject.subject}</h4>

                                    {resultDetail.oral_marks !== undefined && (
                                        <TextField
                                            id={`oral_marks_${resultDetail._id}`}
                                            name={`oral_marks_${resultDetail._id}`}
                                            label="Oral Marks"
                                            type="number"
                                            min={0}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values[`oral_marks_${resultDetail._id}`]}
                                            error={formik.touched[`oral_marks_${resultDetail._id}`] && formik.errors[`oral_marks_${resultDetail._id}`]}
                                            helperText={formik.touched[`oral_marks_${resultDetail._id}`] && formik.errors[`oral_marks_${resultDetail._id}`]}
                                        />
                                    )}

                                    {resultDetail.grace_marks !== undefined && (
                                        <TextField
                                            id={`grace_marks_${resultDetail._id}`}
                                            name={`grace_marks_${resultDetail._id}`}
                                            label="Grace Marks"
                                            type="number"
                                            min={0}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values[`grace_marks_${resultDetail._id}`]}
                                            error={formik.touched[`grace_marks_${resultDetail._id}`] && formik.errors[`grace_marks_${resultDetail._id}`]}
                                            helperText={formik.touched[`grace_marks_${resultDetail._id}`] && formik.errors[`grace_marks_${resultDetail._id}`]}
                                        />
                                    )}

                                    <TextField
                                        id={`written_marks_${resultDetail._id}`}
                                        name={`written_marks_${resultDetail._id}`}
                                        label="Written Marks"
                                        type="number"
                                        min={0}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values[`written_marks_${resultDetail._id}`]}
                                        error={formik.touched[`written_marks_${resultDetail._id}`] && formik.errors[`written_marks_${resultDetail._id}`]}
                                        helperText={formik.touched[`written_marks_${resultDetail._id}`] && formik.errors[`written_marks_${resultDetail._id}`]}
                                    />
                                </div>
                            ))}

                            <Button
                                type="submit"
                                size="large"
                                variant="contained"
                                startIcon={<SaveIcon/>}
                                style={{marginTop: "15px"}}
                            >
                                Save
                            </Button>
                            <Button
                                type="button"
                                size={"large"}
                                variant="contained"
                                color="warning"
                                onClick={() => navigate(-1)}
                                fullWidth
                                startIcon={<CancelIcon />}
                                style={{ marginBottom: "15px" }}
                            >
                                Cancel
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
    return Content;
};

export default EditResultComponent;
