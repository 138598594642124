export function flattenObject(ob) {
  const toReturn = {};

  Object.keys(ob).map((i) => {
    if (typeof ob[i] === "object" && ob[i] !== null) {
      const flatObject = flattenObject(ob[i]);
      Object.keys(flatObject).map((x) => {
        toReturn[`${i}.${x}`] = flatObject[x];
        return x;
      });
    } else {
      toReturn[i] = ob[i];
    }
    return i;
  });
  return toReturn;
}

export const disableSunday = (date) => {
  // Disable Sundays
  return date.$d.getDay() === 0;
};

export const formatDate = (value) => {
  const date = new Date(value);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
export const formatMonthYear = (value) => {
  const date = new Date(value);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  return `${year}-${month}`;
};
