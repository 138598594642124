import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { GetSingleResultTypeFromStore } from "../../services/storeAccessAPI";
import moment from "moment";
import ViewComponent from "../layout/view-component/view.component";

const ViewResultTypeComponent = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [resultTypeData, setResultTypeData] = useState();

  useEffect(() => {
    GetSingleResultTypeFromStore(params.id, dispatch)
      .then((data) => {
        if (data.status !== "rejected") {
          setResultTypeData({
            ...resultTypeData,
            "Result Type ID": data.data._id,
            "Result Type": data.data.result_type,
            "Oral Marks Allowed": data.data.oral_marks_allowed
              ? "Allowed"
              : "Not Allowed",
            "Total Marks": data.data.total_marks,
            "Grace Marks Allowed": data.data.grace_marks_allowed
              ? "Allowed"
              : "Not Allowed",
            "Days Before Result allowed": data.data.days_to_next_result,
            "Add Date": moment(data.data.createdAt).format(
              "DD/MM/YYYY hh:mm a"
            ),
            "Edit Date": moment(data.data.updatedAt).format(
              "DD/MM/YYYY hh:mm a"
            ),
            "Last Updated by": `${data.data.user.firstname} ${data.data.user.lastname}`,
          });
        }
        if (data.status === "rejected") {
          setResultTypeData("");
          console.log("Rejected");
        }
      })
      .catch((error) => {
        console.log(error);
        setResultTypeData("");
      });
    // eslint-disable-next-line
  }, [dispatch, params.id]);
  return (
    <ViewComponent
      title="View Result Type"
      data={resultTypeData}
      icon="bi bi-clipboard-data-fill"
    />
  );
};

export default ViewResultTypeComponent;
