import HeaderComponent from "../../shared/header.component";
import { useGetAllResultQuery } from "../../services/resultAPI";
import Spinner from "../../shared/Spinner";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import createCache from "@emotion/cache";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import MUIDataTable from "mui-datatables";
import { flattenObject } from "../../utils/sharedFunctions";
import moment from "moment/moment";
import { CacheProvider } from "@emotion/react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";

const ResultMasterStaffComponent = () => {
  const navigate = useNavigate();

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const { data, isLoading, isError, error, isSuccess } = useGetAllResultQuery();

  const selectResultMaster = (
    result_master,
    status,
    standard,
    division,
    resultType
  ) => {
    // console.log(result_master, status,standard, division)
    if (status === false) {
      toast.error(`Result ${result_master} already submitted !!`);
    } else {
      navigate(
        `/result-details/${result_master}/${standard}/${division}/${status}/${resultType}`
      );
    }
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              padding: "25px",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              // backgroundColor: "#FF0000",
            },
          },
        },
      },
    });

  const muiCache = createCache({
    key: "mui-datatables",
    prepend: true,
  });

  const columns = [
    {
      name: "_id",
      label: "Result ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "standard._id",
      label: "Standard ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "division._id",
      label: "Division ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "standard.standard",
      label: "Class",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "division.division",
      label: "Division",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "result_type.result_type",
      label: "Result type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "result_date",
      label: "Result Date",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "result_status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "user.email",
      label: "Added By",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "result_type._id",
      label: "Result type ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "addDate",
      label: "Date Added",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "editDate",
      label: "Date Updated",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title="Select Result Master">
                <IconButton
                  onClick={() =>
                    selectResultMaster(
                      tableMeta.rowData[0],
                      tableMeta.rowData[7],
                      tableMeta.rowData[3],
                      tableMeta.rowData[4],
                      tableMeta.rowData[9]
                    )
                  }
                >
                  <NoteAltIcon color="warning" />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "vertical", // tableBodyHeight,
    // tableBodyMaxHeight,
    onTableChange: (action, state) => {
      // console.log(action);
      // console.dir(state);
    },
    rowsPerPage: 5,
    resizableColumns: false,
    rowsPerPageOptions: [5, 10, 15, 25, 50, 100, 150],
    jumpToPage: true,
    showLastButton: true,
    elevation: 0,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total Per Page",
        displayRows: "of",
        jumpToPage: "Page",
      },
      body: {
        noMatch: "No Result Master to display please Generate Result",
      },
    },
    selectableRows: "none",
  };
  if (isLoading) {
    return <Spinner />;
  }
  let Content;
  if (isError && error) {
    Content = (
      <div className="container">
        <div className="row">
          <HeaderComponent
            title="Result Master Staff List"
            icon="bi bi-clipboard-data-fill"
          />
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-3">
            <h1 className="form-title">No Data</h1>
          </div>
        </div>
      </div>
    );
  }
  if (isSuccess && data) {
    // console.log('Data',data)
    // console.log('Data',data.map((_data) => {
    let arrayValues = data.map((_data) => {
      return flattenObject(_data);
    });
    // console.log('Array Values', arrayValues)
    // console.log(arrayValues);
    let TableData = arrayValues.map((row) => {
      const updatedRow = {
        ...row,
        result_status: row.result_status ? "Submitted" : "Pending",
        result_date: moment(row.result_date).format("MMMM Do YYYY"),
        addDate: moment(row.createdAt).format("MMMM Do YYYY"),
        editDate: moment(row.updatedAt).format("MMMM Do YYYY"),
      };
      return updatedRow;
    });
    Content = (
      <div className="container-xxl container-fluid">
        <div className="row">
          <HeaderComponent
            title="Result Master Staff List"
            icon="bi bi-clipboard-data-fill"
          />
          <div className="mb-5  mui-table">
            {/*{JSON.stringify(standard)}*/}
            <CacheProvider value={muiCache}>
              <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  // title={""}
                  data={TableData}
                  columns={columns}
                  options={options}
                />
              </ThemeProvider>
            </CacheProvider>

            <Dialog open={confirmDialog.isOpen}>
              <DialogContent>
                <Typography variant="h6">{confirmDialog.title}</Typography>
                <Typography variant="subtitle2" color={"indigo"}>
                  {confirmDialog.subTitle}
                </Typography>
              </DialogContent>
              <DialogActions sx={{ justifyContent: "center", marginBottom: 2 }}>
                <Button
                  variant={"contained"}
                  color={"error"}
                  onClick={confirmDialog.onConfirm}
                  size={"small"}
                >
                  Yes
                </Button>
                <Button
                  variant={"contained"}
                  color={"primary"}
                  size={"small"}
                  onClick={() =>
                    setConfirmDialog({ ...confirmDialog, isOpen: false })
                  }
                >
                  No
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
  return Content;
};

export default ResultMasterStaffComponent;
