import { useNavigate } from "react-router-dom";
import { useAddDesignationMutation } from "../../services/designationAPI";
import { toast } from "react-toastify";
import { Button, InputAdornment, TextField } from "@mui/material";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFormik } from "formik";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const validationSchema = yup.object({
  designation: yup
    .string("Enter Designation")
    .required("Designation is required"),
});

const AddDesignationComponent = () => {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.auth);

  useEffect(() => {
    if (userData.designation !== "Admin") {
      navigate("/auth-error");
    }
  }, [userData.designation, navigate]);
  const formik = useFormik({
    initialValues: {
      designation: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));
      addDesignation(values)
        .unwrap()
        .then((payload) => {
          toast.success(`Designation added successfully`);
          navigate("/designation");
        })
        .catch((error) =>
          toast.error(`Error adding Designation : ${error.data.message}`)
        );
    },
  });
  const [addDesignation] = useAddDesignationMutation();

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-5">
            <div className="d-flex text-danger justify-content-center ">
              <i className="bi bi-diagram-3-fill" style={{ fontSize: 50 }}></i>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ marginLeft: "-12px" }}
        >
          <h1 className="form-title">Add Designation</h1>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-3">
          <form onSubmit={formik.handleSubmit}>
            <TextField
              id={"designation"}
              name="designation"
              type="text"
              onChange={formik.handleChange}
              className="mb-4"
              label="Enter Designation"
              fullWidth
              error={
                formik.touched.designation && Boolean(formik.errors.designation)
              }
              helperText={
                formik.touched.designation && formik.errors.designation
              }
              value={formik.values.designation}
              variant={"outlined"}
              InputProps={{
                // classes: { input: classes.input1 },
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmailIcon color={"success"} />
                  </InputAdornment>
                ),
              }}
            />

            <Button
              type="submit"
              size={"large"}
              variant="contained"
              // color="warning"
              fullWidth
              startIcon={<SaveIcon />}
              style={{ marginBottom: "15px", backgroundColor: "#522853" }}
            >
              Save
            </Button>
            <Button
              type="button"
              size={"large"}
              variant="contained"
              color="warning"
              onClick={() => navigate(-1)}
              fullWidth
              startIcon={<CancelIcon />}
              style={{ marginBottom: "15px" }}
            >
              Cancel
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};
export default AddDesignationComponent;
