import HeaderComponent from "../../shared/header.component";
import {useNavigate, useParams} from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import {
  GetResultDetailsFromStore,
  GetSingleResultTypeFromStore,
} from "../../services/storeAccessAPI";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../shared/Spinner";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import "../../print.css";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import IconButton from "@mui/material/IconButton";

const ResultDetailsComponent = () => {
  const params = useParams();
  const [resultDetails, setResultDetails] = useState([{}]);
  const [resultType, setResultType] = useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false);
  const navigate = useNavigate();
  let pageNumber = 0;
  const user = useSelector((state) => state.auth);
  // console.log('params', params)

  useEffect(() => {
    if (user.designation === "Admin") {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [user.designation]);
  useEffect(() => {
    if (!isAdmin && params.status === "pending") {
      setButtonStatus(false);
    }
    if (!isAdmin && params.status === "Submitted") {
      setButtonStatus(true);
    }
    if (isAdmin) {
      setButtonStatus(false);
    }
  }, [isAdmin, params.status]);
  const getResultType = useCallback(() => {
    if (params.resultType.length > 0) {
      GetSingleResultTypeFromStore(params.resultType, dispatch)
        .then((data) => {
          setResultType(data.data);
          // console.log('resultType',data.data)
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line
  }, [params.id, dispatch]);

  const getResultDetailsData = useCallback(() => {
    if (params.id.length > 0) {
      setLoading(true);
      GetResultDetailsFromStore(params.id, dispatch)
        .then((data) => {
          setLoading(false);
          const groupedData = data.data.reduce((acc, curr) => {
            const studentId = curr.student._id;
            if (!acc[studentId]) {
              acc[studentId] = {
                student: curr.student,
                results: [curr],
              };
            } else {
              acc[studentId].results.push(curr);
            }
            return acc;
          }, {});
          const groups = Object.values(groupedData);
          let dataWithoutId = [];
          groups.forEach((group) => {
            dataWithoutId.push(group);
          });
          setResultDetails(dataWithoutId);
          // console.log('resultDetails', dataWithoutId)
        })
        .catch((error) => {
          console.log(error);
        });
    }
    // eslint-disable-next-line
  }, [params.id, dispatch]);

  useEffect(() => {
    getResultDetailsData();
  }, [getResultDetailsData]);
  useEffect(() => {
    getResultType();
  }, [getResultType]);

  if (loading) {
    return <Spinner />;
  }
  const sendData = (student) => {
    // console.log(student)
    const studentName = student.student.firstName + " " + student.student.middleName + " " + student.student.lastName
    const resultType = student.results[0].result_master.result_type.result_type
    // console.log('Student',student.student._id,'ResultMaster',student.results[0].result_master._id)
    // let stData = {
    //   ...student,
    //   resultType,
    // }
    // // console.log("STD-Data",stData)
    // const jsonString = JSON.stringify(stData);
    // navigate('/editResult?jsonData=' + encodeURIComponent(jsonString));
    navigate(`/editResult/${student.student._id}/${student.results[0].result_master._id}/${studentName}/${resultType}`)
  };
  return (
    <div className="container">
      <div className="row">
        <div className="no-print">
          <HeaderComponent
            title="Result Details List"
            icon="bi bi-clipboard-data-fill"
          />
        </div>

        {resultDetails &&
          resultDetails.length > 0 &&
          resultDetails.map((student) => (
            <div key={student?._id} className="col-lg-9 mx-auto print-table">
              <h5 className="text-center sub-title">{`${params.standard}-${params.division} : ${student?.student?.firstName} ${student?.student?.middleName} ${student?.student?.lastName} `}</h5>
              <div className="text-end no-print">
                <IconButton
                  aria-label="edit"
                  color="secondary"
                  disabled={buttonStatus}
                  onClick={()=>sendData(student)}
                >
                  <BorderColorIcon />
                </IconButton>
              </div>
              <TableContainer className="mb-4 mx-auto">
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow key={1}>
                      <TableCell align="left">Subject</TableCell>
                      <TableCell align="left">Written Marks</TableCell>
                      {resultType?.oral_marks_allowed && (
                        <TableCell align="left">Oral Marks</TableCell>
                      )}
                      {resultType?.grace_marks_allowed && (
                        <TableCell align="left">Grace Marks</TableCell>
                      )}
                      <TableCell align="left">Total Marks</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {student?.results?.map((row) => (
                      <TableRow key={row._id}>
                        <TableCell component="th" scope="row">
                          {row.subject.subject}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {row.written_marks}
                        </TableCell>
                        {resultType?.oral_marks_allowed && (
                          <TableCell component="th" scope="row">
                            {row.oral_marks}
                          </TableCell>
                        )}
                        {resultType?.grace_marks_allowed && (
                          <TableCell component="th" scope="row">
                            {row.grace_marks}
                          </TableCell>
                        )}
                        <TableCell component="th" scope="row">
                          {row.total_marks}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <p className="mt-3 me-2 text-end">{`Page : ${(pageNumber =
                  pageNumber + 1)} of ${resultDetails.length} Pages`}</p>
              </TableContainer>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ResultDetailsComponent;
