import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import Spinner from "../../shared/Spinner";
import moment from "moment/moment";
import { GetPerformance } from "../../services/storeAccessAPI";
import { useDispatch } from "react-redux";
import ViewComponent from "../layout/view-component/view.component";

const ViewPerformanceComponent = () => {
  const params = useParams();
  // console.log(params)
  const performanceDate = params.performanceDate;
  const standard = params.standard;
  const division = params.division;
  const student = params.student;
  // eslint-disable-next-line
  const [tableData, setTableData] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const getPerformanceData = useCallback(() => {
    setLoading(true);
    GetPerformance(performanceDate, standard, division, student, dispatch)
      .then((data) => {
        // console.log(data.data.performanceDate)
        setLoading(false);
        setTableData({
          Month: moment(data.data.performanceDate).format("MMMM"),
          Student: `${data.data.student.firstName} ${data.data.student.middleName} ${data.data.student.lastName}`,
          "Admission No ": data.data.admissionNo,
          Standard: data.data.standard.standard,
          Division: data.data.division.division,
          "Total Days": data.data.totalAttendance,
          "Days Absent": data.data.absentDays,
          Percentage: data.data.percentage,
          "Performance Note": data.data.performance,
        });

        // console.log(tableData);
        // console.log(formData);
      })
      .catch((error) => {
        console.log(error.message);
      });
    // eslint-disable-next-line
  }, [performanceDate, standard, division, student, dispatch]);

  useEffect(() => {
    getPerformanceData();
  }, [getPerformanceData]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="container">
      <div className="row mb-5">
        <ViewComponent
          title="Student Performance"
          icon="bi bi-bar-chart-fill"
          data={tableData}
        />
      </div>
    </div>
  );
};
export default ViewPerformanceComponent;
