import { apiSlice } from "../app/apiSlice";

// getAllStandard,
// getSingleStandard,
// addStandard,
// updateStandard,
// deleteStandard,

export const resultTypeAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllResultType: builder.query({
      query: () => "/resultType",
      providesTags: ["ResultType"],
    }),
    getSingleResultType: builder.query({
      query: (id) => `/resultType/${id}`,
      providesTags: ["ResultType"],
    }),
    addResultType: builder.mutation({
      query: (resultType) => ({
        url: `/resultType`,
        method: "POST",
        body: resultType,
      }),
      invalidatesTags: ["ResultType"],
    }),
    updateResultType: builder.mutation({
      query: ({ _id, ...rest }) => ({
        url: `/resultType/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["ResultType"],
    }),
    deleteResultType: builder.mutation({
      query: (id) => ({
        url: `/resultType/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["ResultType"],
    }),
  }),
});

export const {
  useGetAllResultTypeQuery,
  useGetSingleResultTypeQuery,
  useAddResultTypeMutation,
  useUpdateResultTypeMutation,
  useDeleteResultTypeMutation,
} = resultTypeAPI;
