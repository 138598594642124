import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import ViewComponent from "../layout/view-component/view.component";
import { GetSingleStudentFromStore } from "../../services/storeAccessAPI";
import Spinner from "../../shared/Spinner";

const ViewStudentComponent = () => {
  const params = useParams();
  const [student, setStudent] = useState();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const getData = useCallback(() => {
    setLoading(true);
    GetSingleStudentFromStore(params.id, dispatch)
      .then((data) => {
        setLoading(false);
        // console.log(data.data.standard._id);
        setStudent({
          ...student,
          ID: data.data._id,
          Name: `${data.data.firstName} ${data.data.lastName}`,
          Gender: data.data.gender,
          Standard: data.data.standard.standard,
          Division: data.data.division.division,
          "House Sports": data.data.house_sports
            ? data.data.house_sports
            : "No Data",
          "Admission #": data.data.admissionNo,
          "Joining Date": moment(data.data.admissionDate).format("DD/MM/YYYY"),
          "Leaving Date": data.data.leavingDate
            ? moment(data.data.leavingDate).format("DD/MM/YYYY")
            : "No Data",
          "Aadhaar #": data.data.aadhaarNo ? data.data.aadhaarNo : "No Data",
          "Birth Date": moment(data.data.birthDate).format("DD/MM/YYYY"),
          "Blood Group": data.data.bloodGroup
            ? data.data.bloodGroup
            : "No Data",
          Address: data.data.address,
          ContactNo1: data.data.contactNo1,
          ContactNo2: data.data.contactNo2 ? data.data.contactNo2 : "No Data",
          WhatsAppNo: data.data.whatsAppNo,
          EmailID: data.data.emailId ? data.data.emailId : "No Data",
          EmergencyContactName: data.data.emergencyContactName,
          EmergencyContactNo: data.data.emergencyContactNo,
          EmergencyContactRelation: data.data.emergencyContactRelationship,
          EmergencyAddress: data.data.emergencyAddress
            ? data.data.emergencyAddress
            : "No Data",
          "Father Name": data.data.fatherName,
          "Father Qualification": data.data.fatherEduQualification
            ? data.data.fatherEduQualification
            : "No Data",
          "Father Designation": data.data.fatherDesignation
            ? data.data.fatherDesignation
            : "No Data",
          "Father OfficeAddress": data.data.fatherOfficeAddress
            ? data.data.fatherOfficeAddress
            : "No Data",
          "Father ContactNo1": data.data.fatherContactNo1
            ? data.data.fatherContactNo1
            : "No Data",
          "Father ContactNo2": data.data.fatherContactNo2
            ? data.data.fatherContactNo2
            : "No Data",
          "Father EmailId": data.data.fatherEmailId
            ? data.data.fatherEmailId
            : "No Data",
          "Father AadhaarNo": data.data.fatherAadhaarNo
            ? data.data.fatherAadhaarNo
            : "No Data",
          "Mother Name": data.data.motherName,
          "Mother Qualification": data.data.motherEduQualification
            ? data.data.motherEduQualification
            : "No Data",
          "Mother Designation": data.data.motherDesignation
            ? data.data.motherDesignation
            : "No Data",
          "Mother OfficeAddress": data.data.motherOfficeAddress
            ? data.data.motherOfficeAddress
            : "No Data",
          "Mother ContactNo1": data.data.motherContactNo1
            ? data.data.motherContactNo1
            : "No Data",
          "Mother ContactNo2": data.data.motherContactNo2
            ? data.data.motherContactNo2
            : "No Data",
          "Mother EmailId": data.data.motherEmailId
            ? data.data.motherEmailId
            : "No Data",
          "Mother AadhaarNo": data.data.motherAadhaarNo
            ? data.data.motherAadhaarNo
            : "No Data",
          Siblings: data.data.siblings ? data.data.siblings : "No Data",
          "Add Date": moment(data.data.createdAt).format("DD/MM/YYYY hh:mm a"),
          "Edit Date": moment(data.data.updatedAt).format("DD/MM/YYYY hh:mm a"),
          "Last Updated by": `${data.data.user.firstname} ${data.data.user.lastname}`,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, [params.id, dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  if (loading) {
    // console.log("loading");
    return <Spinner />;
  }

  return (
    <ViewComponent
      title="View Student"
      data={student}
      icon="bi bi-person-vcard-fill"
    />
  );
};
export default ViewStudentComponent;
