import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { GetSingleDivisionFromStore } from "../../services/storeAccessAPI";
import { useDispatch } from "react-redux";
import moment from "moment/moment";
import ViewComponent from "../layout/view-component/view.component";

const ViewDivisionComponent = () => {
  const [division, setDivision] = useState();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    GetSingleDivisionFromStore(params.id, dispatch)
      .then((data) => {
        // console.log(data.data);
        if (data.status !== "rejected") {
          setDivision({
            ...division,
            ID: data.data._id,
            Standard: data.data.standard.standard,
            Division: data.data.division,
            "Add Date": moment(data.data.createdAt).format(
              "DD/MM/YYYY hh:mm a"
            ),
            "Edit Date": moment(data.data.updatedAt).format(
              "DD/MM/YYYY hh:mm a"
            ),
            "Last Updated by": `${data.data.user.firstname} ${data.data.user.lastname}`,
          });
        }
        if (data.status === "rejected") {
          setDivision("");
          console.log("Rejected");
        }
      })
      .catch((error) => {
        console.log(error);
        setDivision("");
      });
    // eslint-disable-next-line
  }, []);

  return (
    <ViewComponent
      title="View Division"
      data={division}
      icon="bi bi-bar-chart-steps"
    />
  );
};
export default ViewDivisionComponent;
