import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import moment from "moment";
import { GetSingleSubjectFromStore } from "../../services/storeAccessAPI";
import ViewComponent from "../layout/view-component/view.component";

const ViewSubjectsComponent = () => {
  const [subject, setSubject] = useState();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    GetSingleSubjectFromStore(params.id, dispatch)
      .then((data) => {
        // console.log(data.data);
        if (data.status !== "rejected") {
          setSubject({
            ...subject,
            ID: data.data._id,
            Standard: data.data.standard.standard,
            Subject: data.data.subject,
            "Add Date": moment(data.data.createdAt).format(
              "DD/MM/YYYY hh:mm a"
            ),
            "Edit Date": moment(data.data.updatedAt).format(
              "DD/MM/YYYY hh:mm a"
            ),
            "Last Updated by": `${data.data.user.firstname} ${data.data.user.lastname}`,
          });
        }
        if (data.status === "rejected") {
          setSubject("");
          console.log("Rejected");
        }

        // console.log(subject);
      })
      .catch((error) => {
        console.log(error);
        setSubject("");
      });
    // eslint-disable-next-line
  }, []);

  return (
    <ViewComponent title="View Subject" data={subject} icon="bi bi-book-half" />
  );
};
export default ViewSubjectsComponent;
