const HeaderComponent = (props) => {
  return (
    <>
      <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 mx-auto mt-md-4 mt-2"></div>
      <div className="d-flex text-danger justify-content-center">
        <i className={props.icon} style={{ fontSize: 50 }}></i>
      </div>
      <div className="d-flex justify-content-center">
        <h1 className="form-title">{props.title}</h1>
      </div>
      <div className="d-flex justify-content-center">
        {props.subtitle ? (
          <p className="sub-title">{props.subtitle}</p>
        ) : (
          <p></p>
        )}
      </div>
    </>
  );
};
export default HeaderComponent;
