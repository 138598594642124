import Spinner from "../../shared/Spinner";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import { Visibility } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { flattenObject } from "../../utils/sharedFunctions";
import { useNavigate } from "react-router-dom";
import { useGetAllUserQuery } from "../../services/authApi";
import KeyIcon from "@mui/icons-material/Key";

const UsersComponent = () => {
  const navigate = useNavigate();
  const { data, isLoading, isError, error, isSuccess } = useGetAllUserQuery();
  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTable: {
          styleOverrides: {
            root: {
              padding: "25px",
            },
          },
        },
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              // backgroundColor: "#FF0000",
            },
          },
        },
      },
    });

  const muiCache = createCache({
    key: "mui-datatables",
    prepend: true,
  });

  const columns = [
    {
      name: "_id",
      label: "ID",
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: "fullname",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "isActive",
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "designation.designation",
      label: "Designation",
      options: {
        filter: true,
        sort: true,
      },
    },

    // {
    //   name: "addDate",
    //   label: "Date Added",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    {
      name: "editDate",
      label: "Updated",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "actions",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <>
              <Tooltip title="View User">
                <IconButton
                  onClick={() =>
                    navigate("/users/view/" + tableMeta.rowData[0])
                  }
                >
                  <Visibility color={"primary"} />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit User">
                <IconButton
                  onClick={() =>
                    navigate("/users/edit/" + tableMeta.rowData[0])
                  }
                >
                  <EditIcon color={"warning"} />
                </IconButton>
              </Tooltip>
              <Tooltip title={`Change ${tableMeta.rowData[1]} Password`}>
                <IconButton
                  onClick={() =>
                    navigate(
                      "/users/change-user-password/" + tableMeta.rowData[0]
                    )
                  }
                >
                  <KeyIcon color={"success"} />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    },
  ];

  const HeaderElements = () => (
    <>
      <Tooltip title="Add User">
        <IconButton onClick={() => navigate("/users/add")}>
          <AddCircleIcon
            color={"warning"}
            sx={{ fontSize: { xs: 26, sm: 32 } }}
          />
        </IconButton>
      </Tooltip>
    </>
  );

  const options = {
    search: true,
    download: true,
    print: true,
    viewColumns: true,
    filter: true,
    filterType: "dropdown",
    responsive: "vertical",
    // tableBodyHeight,
    // tableBodyMaxHeight,
    onTableChange: (action, state) => {
      // console.log(action);
      // console.dir(state);
    },
    rowsPerPage: 5,
    resizableColumns: false,
    rowsPerPageOptions: [5, 10, 15, 25, 50, 100, 150],
    jumpToPage: true,
    showLastButton: true,
    elevation: 0,
    textLabels: {
      pagination: {
        next: "Next >",
        previous: "< Previous",
        rowsPerPage: "Total Per Page",
        displayRows: "of",
        jumpToPage: "Page",
      },
      body: {
        noMatch: "No Users to display please Add User",
      },
    },
    selectableRows: "none",
    customToolbar: () => <HeaderElements />,
  };
  let Content;
  if (isLoading) {
    return <Spinner />;
  }

  if (isError && error) {
    Content = (
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 mx-auto mt-2"></div>
          <div className="d-flex text-danger justify-content-center">
            <i className="bi bi-people-fill" style={{ fontSize: 50 }}></i>
          </div>
          <div className="d-flex justify-content-center">
            <h1 className="form-title">Users</h1>
          </div>
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-3">
            <h1 className="form-title">No Data</h1>
          </div>
        </div>
      </div>
    );
  }

  if (isSuccess && data) {
    let arrayValues = data.map((_data) => {
      return flattenObject(_data);
    });
    // console.log(arrayValues);
    let TableData = arrayValues.map((row) => {
      const updatedRow = {
        ...row,
        isActive: row.isActive ? "Active" : "Disabled",
        // addDate: moment(row.createdAt).format("DD/MM/YYYY"),
        editDate: moment(row.updatedAt).format("DD/MM/YYYY"),
      };
      return updatedRow;
    });
    Content = (
      <div className="container-xxl container-fluid">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 mx-auto mt-2"></div>
          <div className="d-flex text-danger justify-content-center">
            <i className="bi bi-people-fill" style={{ fontSize: 50 }}></i>
          </div>
          <div className="d-flex justify-content-center">
            <h1 className="form-title">Users</h1>
          </div>
          <div className="mb-5 data-table  mui-table">
            {/*{JSON.stringify(standard)}*/}
            <CacheProvider value={muiCache}>
              <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  // title={""}
                  data={TableData}
                  columns={columns}
                  options={options}
                />
              </ThemeProvider>
            </CacheProvider>
          </div>
        </div>
      </div>
    );
  }

  return <>{Content}</>;
};
export default UsersComponent;
