import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { GetAttendancesPerformanceByMonthStandardDivisionStudent } from "../../services/storeAccessAPI";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import ViewComponent from "../layout/view-component/view.component";
import Spinner from "../../shared/Spinner";
import moment from "moment";
import { Button, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useAddPerformanceMutation } from "../../services/performanceAPI";
import { toast } from "react-toastify";
import { formatMonthYear } from "../../utils/sharedFunctions";

const validationSchema = yup.object({
  performance: yup.string().required("Performance value is required"),
});

const AddPerformanceComponent = () => {
  const params = useParams();
  const navigate = useNavigate();
  const queryPerformanceDate = params.performanceDate;
  const queryStandard = params.standard;
  const queryDivision = params.division;
  const queryStudent = params.student;
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    performanceDate: "",
    student: "",
    admissionNo: "",
    standard: "",
    division: "",
    totalAttendance: "",
    absentDays: "",
    percentage: "",
    performance: "",
  });
  const [tableData, setTableData] = useState();
  const [addPerformance] = useAddPerformanceMutation();

  const getPerformanceData = useCallback(() => {
    setLoading(true);
    GetAttendancesPerformanceByMonthStandardDivisionStudent(
      queryPerformanceDate,
      queryStandard,
      queryDivision,
      queryStudent,
      dispatch
    )
      .then((data) => {
        // console.log(data.data.performanceDate)
        setLoading(false);
        setTableData({
          Month: moment(data.data.performanceDate).format("MMMM"),
          Student: data.data.studentName,
          "Admission No ": data.data.admissionNo,
          Standard: data.data.standardName,
          Division: data.data.divisionName,
          "Total Days": data.data.totalAttendance,
          "Days Absent": data.data.absentDays,
          Percentage: data.data.percentage,
        });
        setFormData({
          ...formData,
          performanceDate: data.data.performanceDate,
          student: data.data.student,
          admissionNo: data.data.admissionNo,
          standard: data.data.standard,
          division: data.data.division,
          totalAttendance: data.data.totalAttendance,
          absentDays: data.data.absentDays,
          percentage: data.data.percentage,
          performance: "",
        });
        // console.log(tableData);
        // console.log(formData);
      })
      .catch((error) => {
        console.log(error.message());
      });
    // eslint-disable-next-line
  }, [
    queryPerformanceDate,
    queryStandard,
    queryDivision,
    queryStudent,
    dispatch,
  ]);

  useEffect(() => {
    getPerformanceData();
  }, [getPerformanceData]);

  const formik = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(values)
      values.performanceDate = formatMonthYear(values.performanceDate);
      addPerformance(values)
        .unwrap()
        .then((payload) => {
          toast.success(`Performance added successfully`);
          navigate("/performance");
        })
        .catch((error) =>
          toast.error(`Error adding Performance : ${error.data.message}`)
        );
    },
  });
  if (loading) {
    return <Spinner />;
  }
  return (
    <div className="container">
      <div className="row mb-5">
        <ViewComponent
          title="Add Performance"
          icon="bi bi-bar-chart-fill"
          data={tableData}
          myProp="0"
        />
        <div className="col-12 col-xxl-11 mx-auto">
          <form onSubmit={formik.handleSubmit}>
            <TextField
              multiline={true}
              rows={4}
              name="performance"
              type="text"
              onChange={formik.handleChange}
              label="Performance Note"
              error={
                formik.touched.performance && Boolean(formik.errors.performance)
              }
              helperText={
                formik.touched.performance && formik.errors.performance
              }
              value={formik.values.performance}
              placeholder="Enter Performance Note"
              onFocus={formik.handleBlur}
              className="mb-4"
              fullWidth
            />
            <Button
              type="submit"
              size={"large"}
              variant="contained"
              // color="warning"

              fullWidth
              startIcon={<SaveIcon />}
              style={{ marginBottom: "15px", backgroundColor: "#522853" }}
            >
              Save
            </Button>
            <Button
              type="button"
              size={"large"}
              variant="contained"
              color="warning"
              onClick={() => navigate(-1)}
              fullWidth
              startIcon={<CancelIcon />}
              style={{ marginBottom: "15px" }}
            >
              Cancel
            </Button>
          </form>
        </div>
      </div>
    </div>
  );
};
export default AddPerformanceComponent;
