import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { GetSingleDesignationFromStore } from "../../services/storeAccessAPI";
import moment from "moment";
import ViewComponent from "../layout/view-component/view.component";

const ViewDesignationComponent = () => {
  const [designation, setDesignation] = useState();
  const params = useParams();
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (userData.designation !== "Admin") {
      navigate("/auth-error");
    }
  }, [userData.designation, navigate]);

  useEffect(() => {
    GetSingleDesignationFromStore(params.id, dispatch)
      .then((data) => {
        if (data.status !== "rejected") {
          // console.log(data.data);
          setDesignation({
            ...designation,
            ID: data.data._id,
            Designation: data.data.designation,
            "Add Date": moment(data.data.createdAt).format(
              "DD/MM/YYYY hh:mm a"
            ),
            "Edit Date": moment(data.data.updatedAt).format(
              "DD/MM/YYYY hh:mm a"
            ),
          });
        }
        if (data.status === "rejected") {
          setDesignation("");
          console.log("Rejected");
        }
      })
      .catch((error) => {
        console.log(error);
        setDesignation("");
      });
    // eslint-disable-next-line
  }, [dispatch, params.id]);

  return (
    <ViewComponent
      title="View Designation"
      data={designation}
      icon="bi bi-diagram-3-fill"
    />
  );
};
export default ViewDesignationComponent;
