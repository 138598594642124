import "./navbar.component.css";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { auth_reset } from "../../../app/authSlice";
import { apiSlice } from "../../../app/apiSlice";
import axios from "axios";
import { useState } from "react";
import Spinner from "../../../shared/Spinner";

const NavbarComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const data = useSelector((state) => state.auth);
  // console.log(data)
  const [loading, setLoading] = useState(false);

  const logout = () => {
    localStorage.removeItem("token");
    dispatch(auth_reset());
    dispatch(apiSlice.util.resetApiState());
    toast.success("Successfully logged out");
    navigate("/");
  };

  const apiUrl =
      process.env.NODE_ENV === "production"
          ? "https://smsapi.sdcodefusion.com/api/"
          : "http://localhost:3001/api/";
  const getHeaders = () => {
    let headers = "";
    if (localStorage.getItem("token")) {
      // headers.set("Authorization", `Bearer ${localStorage.getItem("token")}`);
      headers = `Bearer ${localStorage.getItem("token")}`;
    }
    return headers;
  };
  const downloadBackup = (response) => {
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    const backupTime = new Date().toISOString().replace(/[:.]/g, "-");
    const fileName = `backup-${backupTime}.gz`;

    if (window.navigator.msSaveOrOpenBlob) {
      // IE11
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // Other browsers
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 0);
    }
  };
  const handleCollapse = () => {
    document.querySelector(".navbar-collapse").classList.remove("show");
  };

  const handleDownload = async () => {
    setLoading(true);

    axios
        .get(`${apiUrl}users/backup`, {
          headers: {
            Authorization: getHeaders(),
          },
          responseType: "blob",
        })
        .then((response) => {
          downloadBackup(response);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error downloading backup:", error);
          setLoading(false);
        });
  };

  if (loading) {
    return <Spinner />;
  }
  return (
      <>
        <nav
            className="navbar  navbar-expand-xl no-print"
            style={{ backgroundColor: "#34495E" }}
        >
          <div className="container-fluid">
            <div className="justify-content-start">
              <button
                  className="navbar-toggler"
                  // style={{ fontSize: 24 }}
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  type="button"
                  id="navbarBtn"
              >
                <span className="navbar-toggler-icon"></span>
                {/*<i className="menu-icon bi bi-list"></i>*/}
              </button>
            </div>
            <div className="navbar-brand text-white d-none d-sm-block">
              <i className="menu-icon bi bi-mortarboard"></i>
              <span className="text-white" style={{ marginLeft: "7px" }}>
              School Management System
            </span>
            </div>
            <div className="navbar-brand text-white d-block d-sm-none">
              <i className="menu-icon bi bi-mortarboard"></i>
              <span className="text-white" style={{ marginLeft: "7px" }}>
              SMS
            </span>
            </div>
            <div
                className="collapse navbar-collapse justify-content-center "
                id="navbarNav"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink
                      className="nav-link "
                      to="/"
                      onClick={() => handleCollapse()}
                      style={({ isActive }) =>
                          isActive ? { color: "yellow" } : { color: "white" }
                      }
                      aria-current="page"
                  >
                    <i className="menu-icon bi bi-house-down"></i> Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                      className="nav-link"
                      style={({ isActive }) =>
                          isActive ? { color: "yellow" } : { color: "white" }
                      }
                      to="/performance"
                      onClick={() => handleCollapse()}
                      aria-current="page"
                  >
                    <i className="menu-icon bi bi-bar-chart-fill"></i> Performance
                  </NavLink>
                </li>
                <li className="nav-item dropdown ">
                  <NavLink
                      className="nav-link dropdown-toggle text-white"
                      to="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                  >
                    <i className="menu-icon bi bi-person-vcard"></i> Students
                  </NavLink>
                  <ul className="dropdown-menu">
                    <li>
                      <NavLink
                          className="dropdown-item"
                          to="/students"
                          end
                          onClick={handleCollapse}
                      >
                        <i className="menu-icon bi bi-person-vcard-fill"></i> All
                        Students
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                          className="dropdown-item"
                          to="/students/enrolled"

                          onClick={handleCollapse}
                      >
                        <i className="menu-icon bi bi-person-vcard-fill"></i>{" "}
                        Enrolled Students
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                          className="dropdown-item"
                          to="/students/former"
                          onClick={handleCollapse}
                      >
                        <i className="menu-icon bi bi-person-vcard"></i> Former
                        Students
                      </NavLink>
                    </li>

                  </ul>
                </li>
                <li className="nav-item dropdown ">
                  <NavLink
                      className="nav-link dropdown-toggle text-white"
                      to="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                  >
                    <i className="menu-icon bi bi-shop-window"></i> Academic
                    Structure
                  </NavLink>
                  <ul className="dropdown-menu">
                    <li>
                      <NavLink
                          className="dropdown-item"
                          to="/standard"
                          onClick={handleCollapse}
                      >
                        <i className="menu-icon bi bi-postcard"></i> Standard
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                          className="dropdown-item"
                          to="/division"
                          onClick={handleCollapse}
                      >
                        <i className="menu-icon bi bi-bar-chart-steps"></i>{" "}
                        Division
                      </NavLink>
                      <NavLink
                          className="dropdown-item"
                          to="/subjects"
                          onClick={handleCollapse}
                      >
                        <i className="menu-icon bi bi-book-half"></i> Subjects
                      </NavLink>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <NavLink
                      className="nav-link"
                      to="/attendance"
                      onClick={handleCollapse}
                      aria-current="page"
                      style={({ isActive }) =>
                          isActive ? { color: "yellow" } : { color: "white" }
                      }
                  >
                    <i className="menu-icon bi bi-calendar-check-fill"></i>{" "}
                    Attendance
                  </NavLink>
                </li>
                <li className="nav-item dropdown ">
                  <NavLink
                      className="nav-link dropdown-toggle text-white"
                      to="#"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                  >
                    <i className="menu-icon bi bi-clipboard-data"></i> Result
                  </NavLink>
                  <ul className="dropdown-menu">
                    <li>
                      <NavLink
                          className="dropdown-item"
                          to="/result-type"
                          onClick={handleCollapse}
                          aria-current="page"
                          style={({ isActive }) =>
                              isActive ? { color: "yellow" } : { color: "white" }
                          }
                      >
                        <i className="menu-icon bi bi bi-clipboard-data-fill"></i>{" "}
                        Result Type
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                          className="dropdown-item"
                          to="/result-master"
                          onClick={handleCollapse}
                          aria-current="page"
                          style={({ isActive }) =>
                              isActive ? { color: "yellow" } : { color: "white" }
                          }
                      >
                        <i className="menu-icon bi bi bi-clipboard-data-fill"></i>{" "}
                        Result Master Admin
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                          className="dropdown-item"
                          to="/result-admin"
                          onClick={handleCollapse}
                          aria-current="page"
                          style={({ isActive }) =>
                              isActive ? { color: "yellow" } : { color: "white" }
                          }
                      >
                        <i className="menu-icon bi bi bi-clipboard-data-fill"></i>{" "}
                        Result Manager Admin
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                          className="dropdown-item"
                          to="/resultMasterStaff"
                          onClick={handleCollapse}
                          aria-current="page"
                          style={({ isActive }) =>
                              isActive ? { color: "yellow" } : { color: "white" }
                          }
                      >
                        <i className="menu-icon bi bi bi-clipboard-data-fill"></i>{" "}
                        Result Master Staff
                      </NavLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <div>
              <ul className="navbar-nav">
                {!data.firstname && (
                    <li className="nav-item me-xxl-5 me-xl-2">
                      <NavLink
                          className="nav-link text-white"
                          to="/login"
                          onClick={handleCollapse}
                          aria-current="page"
                          style={({ isActive }) =>
                              isActive ? { color: "yellow" } : { color: "white" }
                          }
                      >
                        <i className="menu-icon bi bi-person-fill-lock"></i> Login
                      </NavLink>
                    </li>
                )}
                {data.firstname && (
                    <li className="nav-item dropdown me-xxl-2 me-xl-2">
                      <NavLink
                          className="nav-link dropdown-toggle text-white"
                          to="#"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                      >
                        <i className="menu-icon bi bi-person-circle"></i>{" "}
                        {data.firstname === "IT" ? data.lastname : data.firstname}
                      </NavLink>
                      <ul className="dropdown-menu dropdown-menu-end">
                        {data.designation === "Admin" && (
                            <li>
                        <span
                            className="dropdown-item"
                            onClick={() => {
                              navigate("/designation");
                              handleCollapse();
                            }}
                        >
                          <i className="menu-icon bi bi-diagram-3"></i>{" "}
                          Designation
                        </span>
                            </li>
                        )}
                        {data.designation === "Admin" && (
                            <li>
                        <span
                            className="dropdown-item"
                            onClick={() => {
                              navigate("/users");
                              handleCollapse();
                            }}
                        >
                          <i className="menu-icon bi bi-people"></i> Users
                        </span>
                            </li>
                        )}
                        {data.designation === "Admin" && (
                            <li>
                        <span
                            className="dropdown-item"
                            onClick={() => {
                              handleDownload();
                              handleCollapse();
                            }}
                        >
                          <i className="menu-icon bi bi-people"></i> Backup
                          Database
                        </span>
                            </li>
                        )}
                        <li>
                      <span
                          className="dropdown-item"
                          onClick={() => {
                            navigate("/users/change-password");
                            handleCollapse();
                          }}
                      >
                        <i className="menu-icon bi bi-key"></i> Change Password
                      </span>
                        </li>
                        <li>
                      <span
                          className="dropdown-item"
                          onClick={() => {
                            navigate("/dashboard");
                            handleCollapse();
                          }}
                      >
                        <i className="menu-icon bi bi-briefcase"></i> Dashboard
                      </span>
                        </li>
                        <li>
                      <span
                          className="dropdown-item"
                          onClick={() => {
                            logout();
                            handleCollapse();
                          }}
                      >
                        <i className="menu-icon bi bi-file-lock2"></i> Logout
                      </span>
                        </li>
                      </ul>
                    </li>
                )}
              </ul>
            </div>
          </div>
        </nav>
      </>
  );
};

export default NavbarComponent;
