const DashboardComponent = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-6 mx-auto mt-5"></div>
        <div className="d-flex text-danger justify-content-center">
          <i className="bi bi-briefcase-fill" style={{ fontSize: 50 }}></i>
        </div>
        <div className="d-flex justify-content-center">
          <h1 className="form-title">Dashboard</h1>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-3"></div>
      </div>
    </div>
  );
};

export default DashboardComponent;
