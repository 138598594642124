import moment from "moment/moment";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAttendancesByDateStandardDivisionQuery } from "../../services/attendanceAPI";
import Spinner from "../../shared/Spinner";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import { toast } from "react-toastify";
import HeaderComponent from "../../shared/header.component";
import FastRewindIcon from "@mui/icons-material/FastRewind";

const ViewAttendanceComponent = () => {
  const params = useParams();
  const navigate = useNavigate();
  const queryDate = params.date;
  const queryStandard = params.standard;
  const queryDivision = params.division;
  const [attendanceData, setAttendanceData] = useState([]);
  const { data, isLoading, isSuccess, isError, error } =
    useGetAttendancesByDateStandardDivisionQuery({
      queryDate,
      queryStandard,
      queryDivision,
    });
  let Content;
  useEffect(() => {
    if (isSuccess && data) {
      // console.log(data);
      let modData = [];
      // eslint-disable-next-line
      data.map((student) => {
        modData.push({
          attendanceDate: params.attendanceDate,
          student: student._id,
          studentName: student.student.fullname,
          admissionNo: student.admissionNo,
          standard: student.standard._id,
          division: student.division._id,
          standardName: student.standard.standard,
          divisionName: student.division.division,
          attendance: student.attendance,
        });
      });
      setAttendanceData(modData);
      // console.log(modData);
    }
    // eslint-disable-next-line
  }, [isSuccess, data]);

  if (isLoading) {
    return <Spinner />;
  }
  if (isSuccess && data.length === 0) {
    Content = (
      <>
        <div className="container">
          <div className="row">
            <HeaderComponent
              title={`Attendance : ${moment(params.date).format("DD/MM/YYYY")}`}
              icon="bi bi-calendar-check-fill"
            />
          </div>
          <h3 className="sub-title text-center text-danger mt-2">
            Attendance not taken...
          </h3>

          <div className="row mb-5 mt-4">
            <div className="col-md-2 col-6 mx-auto">
              <Button
                type="button"
                size={"large"}
                variant="contained"
                color="secondary"
                onClick={() => navigate(-1)}
                fullWidth
                startIcon={<FastRewindIcon />}
                style={{ marginBottom: "15px" }}
              >
                Go Back
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (isSuccess && data.length > 0) {
    Content = (
      <>
        <div className="container">
          <div className="row">
            <HeaderComponent
              title={`Attendance : ${moment(params.date).format("DD/MM/YYYY")}`}
              icon="bi bi-calendar-check-fill"
            />
            <table className="table  table-striped table-bordered table-hover table-responsive-sm ">
              <thead style={{ backgroundColor: "#FE5632", color: "white" }}>
                <tr>
                  <th scope="col" className="tbHead">
                    #
                  </th>
                  <th scope="col" className="tbHead">
                    Student
                  </th>
                  <th scope="col" className="tbHead">
                    Standard
                  </th>
                  <th scope="col" className="tbHead">
                    Division
                  </th>
                  <th scope="col" className="tbHead">
                    Present
                  </th>
                </tr>
              </thead>
              <tbody>
                {attendanceData.length > 0 &&
                  attendanceData?.map((row, rowIndex) => {
                    let serial = rowIndex + 1;
                    return (
                      <tr key={rowIndex}>
                        <th className="tbCell">{serial}</th>
                        <th className="tbCell">{row.studentName}</th>
                        <th className="tbCell">{row.standardName}</th>
                        <th className="tbCell">{row.divisionName}</th>
                        <th className="tbCell">
                          {row.attendance ? "Present" : "Absent"}
                        </th>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            <div className="row mb-5 mt-2">
              <div className="col-md-6">
                <Button
                  type="button"
                  size={"large"}
                  variant="contained"
                  color="warning"
                  onClick={() => navigate("/attendance")}
                  fullWidth
                  startIcon={<EventAvailableIcon />}
                  style={{ marginBottom: "15px", backgroundColor: "#522853" }}
                >
                  Back to Attendance
                </Button>
              </div>
              <div className="col-md-6">
                <Button
                  type="button"
                  size={"large"}
                  variant="contained"
                  color="warning"
                  onClick={() => navigate("/")}
                  fullWidth
                  startIcon={<HomeIcon />}
                  style={{ marginBottom: "15px" }}
                >
                  Back to Home
                </Button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (isError && error) {
    toast.error(`Error getting Attendance : ${error.data.message}`);
    Content = (
      <>
        <div className="container">
          <div className="row">
            <HeaderComponent
              title={`Attendance : ${moment(params.date).format("DD/MM/YYYY")}`}
              icon="bi bi-calendar-check-fill"
            />
          </div>
          <h3 className="sub-title text-center text-danger mt-2">
            Attendance not taken...
          </h3>

          <div className="row mb-5 mt-4">
            <div className="col-md-2 col-6 mx-auto">
              <Button
                type="button"
                size={"large"}
                variant="contained"
                color="secondary"
                onClick={() => navigate(-1)}
                fullWidth
                startIcon={<FastRewindIcon />}
                style={{ marginBottom: "15px" }}
              >
                Go Back
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }

  return Content;
};
export default ViewAttendanceComponent;
