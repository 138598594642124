import StudentComponent from "./student.component";

const EnrolledStudentsComponent = () => {
  return (
    <StudentComponent
      title="Enrolled Students"
      query="useGetEnrolledStudentQuery"
    />
  );
};
export default EnrolledStudentsComponent;
