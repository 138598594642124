import HeaderComponent from "../../../shared/header.component";
import { useParams } from "react-router-dom";

const UnauthorisedComponent = () => {
  const params = useParams();

  return (
    <div className="container">
      <div className="row">
        <HeaderComponent title="Access Error" icon="bi-person-fill-lock" />
        <h3 className="text-danger text-center mt-3">
          You are not authorised to access {params.message}!!!
        </h3>
      </div>
    </div>
  );
};
export default UnauthorisedComponent;
