import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// const apiUrl =
// process.env.NODE_ENV === "production"
//   ? "https://smsapi.sdcodefusion.com/api/"
//   : "http://localhost:3001/api/";
const apiUrl =
"https://smsapi.learnwithconfidence.co.in/api/"
 ;
console.log(apiUrl)
export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: apiUrl,
    prepareHeaders: (headers) => {
      if (localStorage.getItem("token")) {
        headers.set("Authorization", `Bearer ${localStorage.getItem("token")}`);
      }
      return headers;
    },
  }),

  tagTypes: ["Standard"],
  endpoints: (builder) => ({}),
});
