import { CircularProgress } from "@mui/material";

const Spinner = () => {
  return (
    <div className="overlay">
      <div className="center">
        <CircularProgress size="8rem" />
      </div>
    </div>
    // <Grid
    //   style={{
    //     padding: "150px 5px 0 5px",
    //     width: 350, height:350,
    //     margin: "0 auto",
    //   }}
    // >
    //   <Grid container spacing={1}>
    //     <Grid item xs={12} align="center" mb={2}>
    //       <CircularProgress  size="4rem" />
    //     </Grid>
    //   </Grid>
    // </Grid>
  );
};

export default Spinner;
