import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import Spinner from "../../shared/Spinner";
import moment from "moment/moment";
import { GetPerformanceByClass } from "../../services/storeAccessAPI";
import { useDispatch } from "react-redux";
import HeaderComponent from "../../shared/header.component";
import { Button } from "@mui/material";
import FastRewindIcon from "@mui/icons-material/FastRewind";

const ViewPerformanceClassComponent = () => {
  const params = useParams();
  // console.log(params)
  const performanceDate = params.performanceDate;
  const standard = params.standard;
  const division = params.division;
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [std, setStd] = useState("");
  const dispatch = useDispatch();
  const getPerformanceData = useCallback(() => {
    setLoading(true);
    GetPerformanceByClass(performanceDate, standard, division, dispatch)
      .then((data) => {
        setLoading(false);
        const dbData = data.data;
        setStd(`${dbData[0].standard.standard} ${dbData[0].division.division}`);
        setTableData(dbData);
      })
      .catch((error) => {
        console.log(error.message);
      });
    // eslint-disable-next-line
  }, [performanceDate, standard, division, dispatch]);

  useEffect(() => {
    getPerformanceData();
  }, [getPerformanceData]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <HeaderComponent
        title="Performance By Class"
        icon="bi bi-bar-chart-fill"
        subtitle={`Month : ${moment(performanceDate).format(
          "MMMM"
        )} Class: ${std}`}
      />
      <div className="container">
        <div className="row">
          <table className="table  table-striped table-bordered table-hover table-responsive-sm">
            <thead style={{ backgroundColor: "#FE5632", color: "white" }}>
              <tr>
                <th scope="col" className="tbHead">
                  #
                </th>
                <th scope="col" className="tbHead">
                  Student
                </th>
                <th scope="col" className="tbHead">
                  Performance Note
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 &&
                tableData?.map((row, rowIndex) => {
                  let serial = rowIndex + 1;
                  return (
                    <tr key={rowIndex}>
                      <th className="tbCell">{serial}</th>
                      <th className="tbCell">{`${row.student.firstName} ${row.student.middleName} ${row.student.lastName}`}</th>
                      <th className="tbCell">{row.performance}</th>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="d-flex justify-content-center mt-2">
          <Button
            type="button"
            size={"medium"}
            variant="contained"
            color="secondary"
            onClick={() => navigate(-1)}
            startIcon={<FastRewindIcon />}
            style={{ marginBottom: "15px", marginTop: "5px" }}
          >
            Go Back
          </Button>
        </div>
      </div>
    </>
  );
};
export default ViewPerformanceClassComponent;
