import { apiSlice } from "../app/apiSlice";

// getAllStandard,
// getSingleStandard,
// addStandard,
// updateStandard,
// deleteStandard,

export const standardAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllStandard: builder.query({
      query: () => "/standard",
      providesTags: ["Standard"],
    }),
    getSingleStandard: builder.query({
      query: (id) => `/standard/${id}`,
      providesTags: ["Standard"],
    }),
    addStandard: builder.mutation({
      query: (standard) => ({
        url: `/standard`,
        method: "POST",
        body: standard,
      }),
      invalidatesTags: ["Standard"],
    }),
    updateStandard: builder.mutation({
      query: ({ _id, ...rest }) => ({
        url: `/standard/${_id}`,
        method: "PUT",
        body: rest,
      }),
      invalidatesTags: ["Standard"],
    }),
    deleteStandard: builder.mutation({
      query: (id) => ({
        url: `/standard/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Standard"],
    }),
  }),
});

export const {
  useGetAllStandardQuery,
  useGetSingleStandardQuery,
  useAddStandardMutation,
  useUpdateStandardMutation,
  useDeleteStandardMutation,
} = standardAPI;
