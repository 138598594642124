import React, { useState } from "react";
import HeaderComponent from "../../shared/header.component";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useAddResultTypeMutation } from "../../services/resultTypeAPI";
import { Button, InputAdornment, TextField } from "@mui/material";
import VerifiedIcon from "@mui/icons-material/Verified";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { toast } from "react-toastify";
import useAdminAuth from "../../hooks/useAdminAuth";

const validationSchema = yup.object({
  result_type: yup
    .string("Enter Result Type")
    .required("Result Type is required"),
  oral_marks_allowed: yup.boolean(),
  total_marks: yup.number("Enter Total Marks").required("Total Marks Required"),
  grace_marks_allowed: yup.boolean(),
  days_to_next_result: yup
    .number("Enter days to next result")
    .required("Days to next result Required"),
});

const AddResultTypeComponent = () => {
  const authState = useAdminAuth();
  const navigate = useNavigate();
  // eslint-disable-next-line
  const [resultTypeData, setResultTypeData] = useState({
    result_type: "",
    oral_marks_allowed: false,
    total_marks: 0,
    grace_marks_allowed: false,
    days_to_next_result: 15,
  });
  const [addResultType] = useAddResultTypeMutation();
  const formik = useFormik({
    initialValues: resultTypeData,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
      addResultType(values)
        .unwrap()
        .then((payload) => {
          toast.success(`Result Type added successfully`);
          navigate("/result-type");
        })
        .catch((error) =>
          toast.error(`Error adding Result Type : ${error.data.message}`)
        );
    },
  });

  return (
    <>
      {authState.designation === "Admin" && (
        <div className="container">
          <div className="row">
            <HeaderComponent
              title="Result Type"
              icon="bi bi-clipboard-data-fill"
            />
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-10 col-lg-8 col-xl-6 col-xxl-6 mx-auto mt-3">
                  <TextField
                    name="result_type"
                    type="text"
                    onChange={formik.handleChange}
                    label="Result Type"
                    error={
                      formik.touched.result_type &&
                      Boolean(formik.errors.result_type)
                    }
                    helperText={
                      formik.touched.result_type && formik.errors.result_type
                    }
                    value={formik.values.result_type}
                    placeholder="Enter Result Type"
                    onFocus={formik.handleBlur}
                    className="mb-4"
                    fullWidth
                    variant={"outlined"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <VerifiedIcon color={"success"} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-10 col-lg-8 col-xl-6 col-xxl-6 mx-auto ">
                  <TextField
                    name="total_marks"
                    type="number"
                    onChange={formik.handleChange}
                    label="Total Marks"
                    error={
                      formik.touched.total_marks &&
                      Boolean(formik.errors.total_marks)
                    }
                    helperText={
                      formik.touched.total_marks && formik.errors.total_marks
                    }
                    value={formik.values.total_marks}
                    placeholder="Enter Total Marks"
                    onFocus={formik.handleBlur}
                    className="mb-4"
                    fullWidth
                    variant={"outlined"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <VerifiedIcon color={"success"} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-10 col-lg-8 col-xl-6 col-xxl-6 mx-auto ">
                  <TextField
                    name="days_to_next_result"
                    type="number"
                    onChange={formik.handleChange}
                    label="Days Before Next Result"
                    error={
                      formik.touched.days_to_next_result &&
                      Boolean(formik.errors.days_to_next_result)
                    }
                    helperText={
                      formik.touched.days_to_next_result &&
                      formik.errors.days_to_next_result
                    }
                    value={formik.values.days_to_next_result}
                    placeholder="Enter Days Before Next Result"
                    onFocus={formik.handleBlur}
                    className="mb-2"
                    fullWidth
                    variant={"outlined"}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <VerifiedIcon color={"success"} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-5 col-lg-4 col-xl-3 col-xxl-3 ms-md-auto mb-3">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.oral_marks_allowed}
                          onChange={formik.handleChange}
                          name="oral_marks_allowed"
                        />
                      }
                      label={
                        formik.values.oral_marks_allowed
                          ? "Oral Marks Allowed"
                          : "Oral Marks Not Allowed"
                      }
                    />
                  </FormGroup>
                </div>

                <div className="col-md-5 col-lg-4 col-xl-3 col-xxl-3 me-md-auto mb-3">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.grace_marks_allowed}
                          onChange={formik.handleChange}
                          name="grace_marks_allowed"
                        />
                      }
                      label={
                        formik.values.grace_marks_allowed
                          ? "Grace Marks Allowed"
                          : "Grace Marks Not Allowed"
                      }
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-3"></div>
                <div className="col-md-10 col-lg-8 col-xl-3 col-xxl-3 ms-lg-auto mx-md-auto mx-xl-auto">
                  <Button
                    type="submit"
                    size={"large"}
                    variant="contained"
                    // color="warning"

                    fullWidth
                    startIcon={<SaveIcon />}
                    style={{ marginBottom: "15px", backgroundColor: "#522853" }}
                  >
                    Save
                  </Button>
                </div>
                <div className="col-md-10 col-lg-8 col-xl-3 col-xxl-3 me-lg-auto mx-md-auto mx-xl-auto">
                  <Button
                    type="button"
                    size={"large"}
                    variant="contained"
                    color="warning"
                    onClick={() => navigate(-1)}
                    fullWidth
                    startIcon={<CancelIcon />}
                    style={{ marginBottom: "15px" }}
                  >
                    Cancel
                  </Button>
                </div>
                <div className="col-xl-3"></div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default AddResultTypeComponent;
