import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import {
  Button,
  FormHelperText,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";

import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFormik } from "formik";
import * as yup from "yup";
import { useGetAllStandardQuery } from "../../services/standardAPI";
import { useAddSubjectMutation } from "../../services/subjectAPI";

const validationSchema = yup.object({
  standard: yup.string("Enter Standard").required("Standard is required"),
  subject: yup.string("Enter Subject").required("Subject is required"),
});

const AddSubjectsComponent = () => {
  const navigate = useNavigate();
  const [standard, setStandard] = useState();
  const [formData] = useState({
    standard: standard,
    subject: "",
  });
  const { data: standardData, isSuccess: standardIsSuccess } =
    useGetAllStandardQuery();
  let Content;
  const [addSubject] = useAddSubjectMutation();
  const formik = useFormik({
    initialValues: formData,
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // console.log(JSON.stringify(values, null, 2));
      addSubject(values)
        .unwrap()
        .then((payload) => {
          toast.success(`Subject added successfully`);
          navigate("/subjects");
        })
        .catch((error) =>
          toast.error(`Error adding Subject : ${error.data.message}`)
        );
    },
  });
  useEffect(() => {
    function fetchData() {
      if (standardIsSuccess && standardData) {
        setStandard(standardData.standard);
      }
    }

    fetchData();
  }, [standardIsSuccess, standardData]);

  if (!standardData) {
    Content = (
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-2">
            <div className="d-flex text-danger justify-content-center ">
              <i className="bi bi-book-half" style={{ fontSize: 50 }}></i>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ marginLeft: "-12px" }}
        >
          <h1 className="form-title">Add Subject</h1>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-3">
          <h3>No Subject found plead add Subject</h3>
        </div>
      </div>
    );
  } else {
    Content = (
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-2">
            <div className="d-flex text-danger justify-content-center ">
              <i className="bi bi-book-half" style={{ fontSize: 50 }}></i>
            </div>
          </div>
        </div>
        <div
          className="d-flex justify-content-center"
          style={{ marginLeft: "-12px" }}
        >
          <h1 className="form-title">Add Subject</h1>
        </div>
        <div className="col-md-6 col-lg-6 col-xl-6 col-xxl-4 mx-auto mt-3">
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth={true} className="mb-3">
              <InputLabel id="demo-simple-select-label" shrink={true}>
                Standard
              </InputLabel>
              <Select
                name="standard"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                error={
                  formik.touched.standard && Boolean(formik.errors.standard)
                }
                helperText={formik.touched.standard && formik.errors.standard}
                value={formik.values.standard}
                label="Standard"
                defaultValue="choose"
                onChange={formik.handleChange}
              >
                <MenuItem disabled value="choose">
                  Select Standard
                </MenuItem>
                {standardData.map((option) => (
                  <MenuItem key={option._id} value={option._id}>
                    {option.standard}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText style={{ color: "red" }}>
                {formik.touched.standard && formik.errors.standard}
              </FormHelperText>
            </FormControl>

            <TextField
              name="subject"
              type="text"
              onChange={formik.handleChange}
              label="Subject"
              error={formik.touched.subject && Boolean(formik.errors.subject)}
              helperText={formik.touched.subject && formik.errors.subject}
              value={formik.values.division}
              placeholder="Enter Subject"
              onFocus={formik.handleBlur}
              className="mb-4"
              fullWidth
              variant={"outlined"}
              InputProps={{
                // classes: { input: classes.input1 },
                endAdornment: (
                  <InputAdornment position="end">
                    <AlternateEmailIcon color={"success"} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              size={"large"}
              variant="contained"
              // color="warning"

              fullWidth
              startIcon={<SaveIcon />}
              style={{ marginBottom: "15px", backgroundColor: "#522853" }}
            >
              Save
            </Button>
            <Button
              type="button"
              size={"large"}
              variant="contained"
              color="warning"
              onClick={() => navigate(-1)}
              fullWidth
              startIcon={<CancelIcon />}
              style={{ marginBottom: "15px" }}
            >
              Cancel
            </Button>
          </form>
        </div>
      </div>
    );
  }

  return Content;
};
export default AddSubjectsComponent;
