import StudentComponent from "./student.component";

const FormerStudentsComponent = () => {
  return (
    <StudentComponent
      title="Former Students"
      query="useGetFormerStudentQuery"
    />
  );
};
export default FormerStudentsComponent;
