import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import FastRewindIcon from "@mui/icons-material/FastRewind";
import Spinner from "../../../shared/Spinner";
import HeaderComponent from "../../../shared/header.component";

const ViewComponent = (props) => {
  const navigate = useNavigate();
  let Content;
  let keys;
  let values;
  if (props.data) {
    keys = Object.keys(props.data);
    values = Object.values(props.data);
  }
  if (props.data === undefined) {
    return <Spinner />;
  }

  if (props.data === 0) {
    Content = (
      <div className="container ">
        <div className="row">
          <HeaderComponent title={props.title} icon={props.icon} />
        </div>

        <div>
          <div className="row ">
            <div className="col-12 col-md-10 col-lg-8 col-xl-8 col-xxl-6 mx-auto mt-3">
              <h4 className="errorMessage text-danger text-center">
                No data to display...
              </h4>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            {props.myProp ? (
              <div></div>
            ) : (
              <Button
                type="button"
                size={"medium"}
                variant="contained"
                color="secondary"
                onClick={() => navigate(-1)}
                startIcon={<FastRewindIcon />}
                style={{ marginBottom: "15px", marginTop: "5px" }}
              >
                Go Back
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    Content = (
      <>
        <HeaderComponent title={props.title} icon={props.icon} />
        <div className="container ">
          <div className="row">
            <div className="col-12 col-xxl-11 mx-auto">
              {props.data && (
                <table className="table table-striped table-hover table-responsive-sm">
                  <tbody>
                    {keys.map((key, index) => (
                      <tr key={index}>
                        <td className="text-end tblFont text-md-start">
                          {key} :
                        </td>
                        <td className="tblFont">{values[index]}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-2">
          {props.myProp ? (
            <div></div>
          ) : (
            <Button
              type="button"
              size={"medium"}
              variant="contained"
              color="secondary"
              onClick={() => navigate(-1)}
              startIcon={<FastRewindIcon />}
              style={{ marginBottom: "15px", marginTop: "5px" }}
            >
              Go Back
            </Button>
          )}
        </div>
      </>
    );
  }

  return Content;
};
export default ViewComponent;
