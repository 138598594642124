import HeaderComponent from "../../shared/header.component";
import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import FormControl from "@mui/material/FormControl";
import { Button, FormHelperText, InputLabel } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { GetAllStudentByStandardDivision } from "../../services/storeAccessAPI";
import { useDispatch } from "react-redux";

const validationSchema = yup.object({
  student: yup.string().required("Student name is required"),
});

const ResultManagerStaffComponent = () => {
  const params = useParams();
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [students, setStudents] = useState();
  // eslint-disable-next-line
  const [formValues, setFormValues] = useState({
    result_master: params.result_master,
    student: "",
  });

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  // console.log(params);
  const getStudentData = useCallback(() => {
    if (params.standard.length > 0 && params.division.length > 0) {
      GetAllStudentByStandardDivision(
        params.standard,
        params.division,
        dispatch
      )
        .then((data) => {
          setLoading(false);
          // console.log(data);
          setStudents(data.data);
          // console.log(studentsData.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [params.standard, params.division, dispatch]);
  useEffect(() => {
    getStudentData();
  }, [getStudentData]);
  return (
    <div className="container">
      <div className="row">
        <HeaderComponent
          title="Result Manager Staff"
          icon="bi bi-clipboard-data-fill"
        />
        <form onSubmit={formik.handleSubmit} className="mt-2 ">
          <div className="row">
            <div className="col-md-6">
              <FormControl fullWidth={true} className="mb-4 ">
                <InputLabel id="demo-simple-select-label">Student</InputLabel>
                <Select
                  name="student"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  error={
                    formik.touched.student && Boolean(formik.errors.student)
                  }
                  value={formik.values.student}
                  label="Student"
                  defaultValue="choose"
                  onChange={formik.handleChange}
                >
                  <MenuItem disabled value="choose">
                    Select Student
                  </MenuItem>
                  {students?.length > 0 &&
                    students?.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        {`${option.firstName} ${option.middleName} ${option.lastName}`}
                      </MenuItem>
                    ))}
                </Select>
                <FormHelperText style={{ color: "red" }}>
                  {formik.touched.student && formik.errors.student}
                </FormHelperText>
              </FormControl>
            </div>

            <div className="col-md-6 mb-3">
              <Button
                type="button"
                size={"small"}
                variant="contained"
                color="error"
                onClick={formik.handleSubmit}
                fullWidth
                style={{ height: "55px" }}
                startIcon={<i className="bi bi-bar-chart-fill"></i>}
                // style={{ marginBottom: "15px" }}
              >
                Submit Result
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResultManagerStaffComponent;
