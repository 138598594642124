import {apiSlice} from "../app/apiSlice";

export const resultAPI = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllResult: builder.query({
            query: () => "/resultMaster",
            cacheTime: 0, // Disable caching
        }),
        getAllPendingResultMaster: builder.query({
            query: () => "/resultMaster/pending",
            cacheTime: 0, // Disable caching
        }),
        getAllResultDetailsByResultMasterId: builder.query({
            query: (id) => `/resultMaster/result/${id}`,
            cacheTime: 0, // Disable caching
        }),
        getAllResultDetailByStudentResultMasterId: builder.query({
            query: (arg) => {
                const {studentID, resultMasterID} = arg;
                // console.log(studentID, resultMasterID);
                return {
                    url: `/resultDetail/${studentID}/${resultMasterID}`,
                };
            },
            cacheTime: 0, // Disable caching
        }),
        getSingleResult: builder.query({
            query: (id) => `/resultMaster/${id}`,
            cacheTime: 0, // Disable caching
        }),
        addResult: builder.mutation({
            query: (result) => ({
                url: `/resultMaster`,
                method: "POST",
                body: result,
            }),
        }),
        updateResult: builder.mutation({
            query: ({_id, ...rest}) => ({
                url: `/resultMaster/${_id}`,
                method: "PUT",
                body: rest,
            }),
        }),
        updateResultDetails: builder.mutation({
            query: (payload) => ({
                url: 'resultDetail/updateResults',
                method: "PUT",
                body: payload,
            }),
        }),
        deleteResult: builder.mutation({
            query: (id) => ({
                url: `/resultMaster/${id}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const {
    useGetAllResultQuery,
    useGetAllPendingResultQuery,
    useGetAllResultDetailsByResultMasterIdQuery,
    useGetAllResultDetailByStudentResultMasterId,
    useGetSingleResultQuery,
    useAddResultMutation,
    useUpdateResultMutation,
    useUpdateResultDetailsMutation,

    useDeleteResultMutation,
} = resultAPI;
