import { apiSlice } from "../app/apiSlice";

export const performanceAPI = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPerformance: builder.query({
      query(arg) {
        const { performanceDate, standard, division, student } = arg;
        // console.log(arg)
        return {
          url: `/performance/${performanceDate}/${standard}/${division}/${student}`,
        };
      },
      providesTags: ["Performance"],
    }),
    getPerformanceByClass: builder.query({
      query(arg) {
        const { performanceDate, standard, division } = arg;
        // console.log(arg)
        return {
          url: `/performance/class/${performanceDate}/${standard}/${division}`,
        };
      },
      providesTags: ["Performance"],
    }),
    addPerformance: builder.mutation({
      query: (data) => ({
        url: "/performance",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Performance"],
    }),
  }),
});

export const {
  useGetPerformanceQuery,
  useGetPerformanceByClassQuery,
  useAddPerformanceMutation,
} = performanceAPI;
