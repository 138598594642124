import "./HomeComponent.css";
import Helmet from "react-helmet";
import ProgressiveImage from "react-progressive-graceful-image";


const HomeComponent = () => {

  return (
    <>
      <Helmet>
        <title>
          School Management System | Manage Your School's Operations with Ease
        </title>
        <meta
          name="description"
          content="Our School Management System provides a centralized and user-friendly solution to manage all aspects of your school's operations. Try it now and experience the difference it makes in your school's efficiency and productivity."
        />
        <meta
          name="keywords"
          content="school management system, student registration, course management, attendance tracking, grade reporting"
        />
        <meta
          property="og:title"
          content="School Management System | Manage Your School's Operations with Ease"
        />
        <meta
          property="og:description"
          content="Our School Management System provides a centralized and user-friendly solution to manage all aspects of your school's operations. Try it now and experience the difference it makes in your school's efficiency and productivity."
        />
        <meta
          property="og:image"
          content="https://sms.sdcodefusion.com/assets/img/school-banner.jpg"
        />
      </Helmet>

      <div className="container">
        <div className="row mt-4">
          <div className="col-xxl-10 mx-auto ">
            <h4 className="mb-4 text-center" style={{ color: "#EB510A" }}>
              Welcome to the School Management System
            </h4>
            <div style={{marginBottom:"20px"}}>
              <ProgressiveImage  placeholder={"/assets/img/school-banner-low.jpg"} src={"/assets/img/school-banner.jpg"} >
                {(src) => <img src={src} width="1030px" height="1280px" className="img-fluid" alt=""  />}
              </ProgressiveImage>
            </div>

            <p
              className="mb-5 text-success"
              style={{ fontSize: "1.2rem", lineHeight: 1.8 }}
            >
              Our platform provides a centralized and user-friendly solution to
              manage all aspects of your school's operations. With features such
              as student registration, course management, attendance tracking,
              and grade reporting, our system streamlines administrative tasks
              and enhances communication between teachers, staff, and parents.
            </p>
            <p
              className="mb-5  text-success"
              style={{ fontSize: "1.2rem", lineHeight: 1.8 }}
            >
              With our easy-to-use interface, you can access important
              information anytime, anywhere. Our system is designed to be
              secure, ensuring that sensitive student information remains
              protected.
            </p>
            <p
              className="mb-5 text-success"
              style={{ fontSize: "1.2rem", lineHeight: 1.8 }}
            >
              Whether you're a teacher, staff member, or administrator, our
              School Management System is the perfect solution for your school.
              Try it now and experience the difference it makes in your school's
              efficiency and productivity.
            </p>
            {/*<p*/}
            {/*  className="mb-5 text-primary text-end me-xxl-5"*/}
            {/*  style={{ fontSize: "1.1rem", lineHeight: 1.5 }}*/}
            {/*>*/}
            {/*  For support or questions, please contact us at*/}
            {/*  <span className="text-danger ms-2">*/}
            {/*    smssoftwaresolutions2023@gmail.com*/}
            {/*  </span>*/}
            {/*</p>*/}
            <p
              className="mb-5 text-success ms-xxl-5"
              style={{ fontSize: "1.1rem", lineHeight: 1.5 }}
            >
              Thank you for choosing our School Management System.
            </p>
          </div>
        </div>
      </div>

      <footer
        className="container-fluid text-white"
        style={{ backgroundColor: "#34495E" }}
      >
        <div className="row justify-content-between align-items-center">
          <div className="col-4 text-center p-2">
            <h5>Contact Us</h5>
            <p>
              123 Main St
              <br />
              City, State 12345
              <br />
              (555) 555-5555
            </p>
          </div>
          <div className="col-4 text-center p-2">
            <h5>About Us</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
              tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
            </p>
          </div>
          <div className="col-4 text-center p-2">
            <h5>Social Media</h5>
            <p>Follow us on:</p>
            <ul className="list-inline">
              <li className="list-inline-item">
                <a href="/">
                  <i className="bi bi-facebook"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="/">
                  <i className="bi bi-twitter"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="/">
                  <i className="bi bi-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </>
  );
};

export default HomeComponent;
