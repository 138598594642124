import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { GetSingleStandardFromStore } from "../../services/storeAccessAPI";
import moment from "moment";
import ViewComponent from "../layout/view-component/view.component";

const ViewStandardComponent = () => {
  const [standard, setStandard] = useState();
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    GetSingleStandardFromStore(params.id, dispatch)
      .then((data) => {
        if (data.status !== "rejected") {
          setStandard({
            ...standard,
            "Standard ID": data.data._id,
            Standard: data.data.standard,
            "Add Date": moment(data.data.createdAt).format(
              "DD/MM/YYYY hh:mm a"
            ),
            "Edit Date": moment(data.data.updatedAt).format(
              "DD/MM/YYYY hh:mm a"
            ),
            "Last Updated by": `${data.data.user.firstname} ${data.data.user.lastname}`,
          });
        }
        if (data.status === "rejected") {
          setStandard("");
          console.log("Rejected");
        }
      })
      .catch((error) => {
        console.log(error);
        setStandard("");
      });
    // eslint-disable-next-line
  }, [dispatch, params.id]);

  return (
    <ViewComponent
      title="View Standard"
      data={standard}
      icon="bi bi-postcard-fill"
    />
  );
};

export default ViewStandardComponent;
